import { createReducer } from '@reduxjs/toolkit';
import { clearFaqs, fetchFaqs, setFaqs } from './faqActions';
import { STORE_STATUS } from 'lib/enums/store';

const initialState = {
  faqs: null,
  error: null,
  status: STORE_STATUS.PENDING,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchFaqs, (state) => {
      state.status = STORE_STATUS.PENDING;
    })
    .addCase(setFaqs, (state, { payload }) => {
      state.faqs = payload;
      state.error = null;
      state.status = STORE_STATUS.RESOLVED;
    })
    .addCase(clearFaqs, (state) => {
      state.faqs = null;
      state.error = null;
      state.status = STORE_STATUS.PENDING;
    });
});
