import { clearFilters } from 'store/search/searchActions';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { getPageFromState } from './helpers';

const emitClearFiltersInteractionEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  return interactionEvent({ type: 'Reset All Filters', value: 'Link Selected', page: page.name });
};

export default {
  [clearFilters]: emitClearFiltersInteractionEvent,
};
