import { css } from '@emotion/core';
import get from 'lodash/get';
import theme from 'lib/theme';

const themeGet = (key) => get(theme, key, 0);

const space1 = themeGet('space.1');
const space2 = themeGet('space.2');
const space4 = themeGet('space.4');

const hidden = {
  display: 'none',
  visibility: 'hidden',
};

const visible = {
  display: 'block',
  visibility: 'visible',
};

const sharedStyles = {
  'div[data-print-style="hidden"]': hidden,
  'span[data-print-style="hidden"]': hidden,

  'div[data-print-style="visible"]': visible,
  'span[data-print-style="visible"]': visible,

  'h1[data-print-style="h1"]': {
    paddingBottom: space1,
    paddingTop: space1,
  },

  'h2[data-print-style="h2"]': {
    fontSize: themeGet('fontSizes.md'),
    marginBottom: 0,
    paddingBottom: space1,
    paddingTop: 0,
  },

  'h3[data-print-style="h3"]': {
    paddingBottom: 0,
  },

  'div[data-print-style="no-border"]': {
    border: 0,
  },

  'div[data-print-style="no-padding"]': {
    padding: 0,
  },

  'div[data-print-style="paragraph"]': {
    border: 0,
    paddingBottom: space2,
    paddingTop: space2,
  },
};

const componentStyles = {
  'div[data-print-style="confirmation-header"]': {
    paddingBottom: space1,
    paddingTop: space1,
    svg: {
      height: 22,
      width: 22,
    },
  },

  'div[data-print-style="content-row"]': {
    justifyContent: 'flex-start',
  },

  'div[data-print-style="content-row-heading"]': {
    width: '30%',
  },

  'div[data-print-style="content-wrapper"]': {
    paddingBottom: space1,
    paddingTop: space1,
  },

  'div[data-print-style="page-block"]': {
    borderColor: themeGet('colors.greys.alto'),
    paddingBottom: space4,
    paddingTop: space4,
  },

  'div[data-print-style="section"]': {
    paddingBottom: space4,
    paddingTop: space4,
    pageBreakInside: 'avoid',
  },
};

export default css({
  '@media print': {
    ...sharedStyles,
    ...componentStyles,
  },
});
