import { checkoutError } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitCheckoutError = ({ payload }) => {
  const checkoutErrorEvent = createEvent({
    type: EventType.CHECKOUT_ERROR,
    payload: {
      errors: payload.errors,
    },
  });

  return checkoutErrorEvent;
};

// eslint-disable-next-line
// @ts-ignore
export default { [checkoutError]: emitCheckoutError };
