import { menuClick } from 'store/ui/uiActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitMenuClickEvent = ({ payload }) => {
  return createEvent({
    type: EventType.MENU_CLICK,
    payload: {
      itemText: payload?.itemText,
      url: payload?.url,
    },
  });
};

// eslint-disable-next-line
// @ts-ignore
export default { [menuClick]: emitMenuClickEvent };
