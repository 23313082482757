import { createLogic } from 'redux-logic';

import { getRecommendedPropertyAvailabilityCardsData } from '../recommendedPropertySelectors';
import { fetchRecommendedPropertyAvailabilitySuccess } from '../recommendedPropertyActions';
import { registerImpressions } from '../../../lib/analytics/recommendations';
import { getOptimizely } from 'lib/optimizely';

export const trackRecommendedPropertyImpressionsLogic = createLogic({
  type: fetchRecommendedPropertyAvailabilitySuccess,
  async process({ getState }, dispatch, done) {
    const state = getState();
    const optimizely = getOptimizely();
    const treatment = optimizely.getFeatureVariables('qantas-hotels-property-page-recommendations').recommendation;

    const properties = getRecommendedPropertyAvailabilityCardsData(state);
    const propertyIds = properties.map((property) => property.id);
    registerImpressions({ propertyIds, method: treatment, placement: 'property_page' });
    done();
  },
});
