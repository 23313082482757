import { createReducer } from '@reduxjs/toolkit';
import {
  clearRecommendedPropertyAvailability,
  fetchRecommendedPropertyAvailabilityFailure,
  fetchRecommendedPropertyAvailabilitySuccess,
  setLoading,
} from './recommendedPropertyActions';

const initialState = {
  isLoading: false,
  results: [],
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearRecommendedPropertyAvailability, (state) => {
      state.error = null;
      state.results = [];
    })

    .addCase(fetchRecommendedPropertyAvailabilityFailure, (state, { payload }) => {
      state.error = payload;
      state.results = [];
    })

    .addCase(fetchRecommendedPropertyAvailabilitySuccess, (state, { payload }) => {
      state.error = null;
      state.results = payload;
    })

    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    }),
);
