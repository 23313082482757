import { createLogic } from 'redux-logic';
import merge from 'lodash/merge';
import Decimal from 'decimal.js';
import { updatePayments, updateFormData } from 'store/checkout/checkoutActions';
import { getPayments, getPaymentMode } from 'store/checkout/checkoutSelectors';
import { PAYMENT_MODES } from 'lib/enums/payment';
import { getPayableLaterDueDateString, getTotalPayableAtBooking } from 'store/quote/quoteSelectors';

export const updatePaymentsLogic = createLogic({
  type: updatePayments,
  latest: true,
  async process({ action, getState }, dispatch, done) {
    const state = getState();
    const payments = getPayments(state);
    const paymentMode = getPaymentMode(state);
    const totalPayableAtBooking = getTotalPayableAtBooking(state);
    const payableLaterDueDateString = getPayableLaterDueDateString(getState());

    const updatedPayments = merge({}, payments, action.payload);

    let cashPayableLaterAmount = new Decimal(0);

    if (paymentMode === PAYMENT_MODES.DEPOSIT) {
      const payableNowCashAmount = updatedPayments.cash.payableNow.amount ?? new Decimal(0);
      const pointsAmountInCash = updatedPayments.points.amountInCash ?? new Decimal(0);
      const travelPassAmount = updatedPayments.travelPass.amount ?? new Decimal(0);
      const voucherAmount = updatedPayments.voucher.amount ?? new Decimal(0);

      cashPayableLaterAmount = totalPayableAtBooking
        .minus(payableNowCashAmount)
        .minus(pointsAmountInCash)
        .minus(travelPassAmount)
        .minus(voucherAmount);
    }

    updatedPayments.cash.payableLater = {
      amount: cashPayableLaterAmount,
      dueDate: payableLaterDueDateString,
    };

    dispatch(updateFormData({ payments: updatedPayments }));
    done();
  },
});
