import { useSelector } from 'react-redux';
import { pick } from 'lodash';
import { getDefaults } from 'lib/enums/search';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { getQueryParams } from 'store/router/routerSelectors';
import { getPropertyLocation } from 'store/property/propertySelectors';

const SEARCH_DEFAULT_PARAM_KEYS = Object.keys(getDefaults());

const searchQueryString = ({ queryParams, propertyLocation }) => {
  const query = { location: propertyLocation, ...queryParams };
  return stringifyQueryValues(pick(query, SEARCH_DEFAULT_PARAM_KEYS));
};

export const useQueryString = () => {
  const queryParams = useSelector(getQueryParams);
  const propertyLocation = useSelector(getPropertyLocation);
  return searchQueryString({ queryParams, propertyLocation });
};
