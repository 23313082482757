import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { menuClick } from 'store/ui/uiActions';
import { startsWith } from 'lodash';
import { HOTELS_URL } from 'config';

const useMenuClickEvent = () => {
  const dispatch = useDispatch();

  const menuClickEvent = useCallback(
    ({ itemText, url }) => {
      const parsedUrl = startsWith(url, 'http') ? url : HOTELS_URL.concat(url);
      dispatch(
        menuClick({
          itemText: itemText,
          url: parsedUrl,
        }),
      );
    },
    [dispatch],
  );

  return { menuClickEvent };
};

export default useMenuClickEvent;
