import { themeGet } from 'styled-system';
import { minusOnePx } from '.';
import theme from 'lib/theme';

const getBreakpoints = themeGet('breakpoints', theme.breakpoints);

export const createMediaQuery =
  ({ min, max }) =>
  (props) => {
    const breakpoints = getBreakpoints(props);
    let mediaQuery = `@media screen`;

    if (min !== undefined) {
      mediaQuery += ` and (min-width: ${breakpoints[min]})`;
    }

    if (max !== undefined) {
      mediaQuery += ` and (max-width: ${minusOnePx(breakpoints[max])})`;
    }

    return mediaQuery;
  };

export const mediaQuery = {
  minWidth: {
    sm: createMediaQuery({ min: 0 }),
    md: createMediaQuery({ min: 1 }),
    lg: createMediaQuery({ min: 2 }),
  },
  mobileOnlyEscapeHatch: createMediaQuery({ max: 0 }),
  mobileLandscape: () => `@media screen and (max-width: 767px) and (orientation: landscape)`,
};
