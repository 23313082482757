import { COOKIE_NAMES } from 'lib/enums/cookies';
import { setIsVip } from 'store/split/splitActions';
import get from 'lodash/get';

const loadSplitVip = async ({ req, store }) => {
  const isVip = !!get(req, `cookies.${COOKIE_NAMES.SPLIT_VIP}`);
  store.dispatch(setIsVip(isVip));
};

export default loadSplitVip;
