import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Visibility from 'lib/browser/visibility';
import { fetchCampaign } from 'store/campaign/campaignActions';
import { CAMPAIGN_BANNER_ENABLED } from 'config';
import { useMount } from 'react-use';

const POLLING_INTERVAL = 30000;

const CampaignFetcher = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(fetchCampaign());
  });

  useEffect(() => {
    if (CAMPAIGN_BANNER_ENABLED) {
      const dispatchFetchCampaign = () => dispatch(fetchCampaign());
      const pollerRef = Visibility.every(POLLING_INTERVAL, dispatchFetchCampaign);

      return () => Visibility.stop(pollerRef);
    }
  }, [dispatch]);

  return null;
};

CampaignFetcher.protoTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default CampaignFetcher;
