const getPreviousTiers = (tierName) => {
  const tiers = [
    {
      name: 'VERSION9',
      tiers: [
        { min: 0, rate: 0.00598, max: 150 },
        { min: 150, rate: 0.00605, max: 400 },
        { min: 400, rate: 0.00615, max: 650 },
        { min: 650, rate: 0.00635, max: 900 },
        { min: 900, rate: 0.00675, max: Infinity },
      ],
    },
    {
      name: 'VERSION10',
      tiers: [
        { min: 0, max: 150, rate: 0.00854 },
        { min: 150, max: 400, rate: 0.00864 },
        { min: 400, max: 650, rate: 0.00878 },
        { min: 650, max: 900, rate: 0.00907 },
        { min: 900, max: null, rate: 0.00964 },
      ],
    },
  ];

  const previousTier = tiers.find(({ name }) => name === tierName);

  return previousTier;
};

export default getPreviousTiers;
