import { getDeviceType } from 'store/userEnvironment/userEnvironmentSelectors';
import { getResults as getSearchResults } from 'store/search/searchSelectors';
import { getResultsForActiveCategory as getPromoResults } from 'store/promoArea/promoAreaSelectors';
import { getRecommendedPropertyAvailabilityCardsData as getRecommendedResults } from 'store/recommendedProperty/recommendedPropertySelectors';
import { createSelector } from '@reduxjs/toolkit';

const mapId = (results) => results.map((result) => result.id);

export const getSearchPromoImpression = createSelector(getPromoResults, mapId);

export const getSearchListImpression = createSelector(getSearchResults, (results) => results.map(({ property }) => property.id));

export const getPropertyPageRecommendationsImpression = createSelector(getRecommendedResults, mapId);

export const getCommonEventProperties = createSelector(getDeviceType, (deviceType) => ({
  deviceType,
}));
