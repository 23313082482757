import toNumber from 'lodash/toNumber';

import { isValid } from 'lib/date';

const validDateFormat = (dateAsString) => /\d{4}-\d{1,2}-\d{1,2}/.test(dateAsString);

export const dateFromString = (dateAsString) => {
  if (dateAsString === undefined || dateAsString === null) return dateAsString;
  if (dateAsString && validDateFormat(dateAsString)) {
    const [year, month, day] = dateAsString.split('-').map(toNumber);
    const date = new Date(year, month - 1, day);
    return isValid(date) ? date : undefined;
  }
  return undefined;
};
