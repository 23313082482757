import { createAction } from '@reduxjs/toolkit';

export const login = createAction('user/LOG_IN');
export const loginFailure = createAction('user/LOG_IN_FAILURE');
export const logout = createAction('user/LOG_OUT');
export const setUser = createAction('user/SET_USER', (payload) => ({ payload }));
type SetAuthenticationPrepareAction = (payload: Record<string, unknown>) => { payload: Record<string, unknown> };
export const setAuthentication = createAction<SetAuthenticationPrepareAction>('user/SET_AUTHENTICATION', (payload) => ({ payload }));
export const trackAuthentication = createAction<{ memberId: string }>('user/TRACK_AUTHENTICATION');
export const clearAuthentication = createAction('user/CLEAR_AUTHENTICATION');
export const dismissSearchMessaging = createAction('user/DISMISS_SEARCH_MESSAGING');
export const dismissLoginTooltip = createAction('user/DISMISS_LOGIN_TOOLTIP');
export const dismissWelcomeMessage = createAction('user/DISMISS_WELCOME_MESSAGE');
export const setAuthenticationCompleted = createAction('user/HAS_AUTHENTICATION_COMPLETED');
