import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { push } from 'connected-next-router';
import { getQueryString, getPathName } from 'store/router/routerSelectors';
import queryValuesToString from './queryValuesToString';

export default ({ state, newQuery, dispatch }) => {
  const update = queryValuesToString(newQuery || {});
  const currentQuery = queryString.parse(getQueryString(state));
  const pathname = getPathName(state);
  const updatedQuery = omitBy({ ...currentQuery, ...update }, isEmpty);

  if (!isEqual(updatedQuery, currentQuery)) {
    dispatch(push({ pathname, query: updatedQuery }, undefined, { shallow: true }));
  }
};
