import { createAction } from '@reduxjs/toolkit';

export const setIpAddress = createAction('userEnvironment/SET_IP_ADDRESS');
export const setBrowser = createAction('userEnvironment/SET_BROWSER');
export const setIsBot = createAction('userEnvironment/SET_IS_CRAWLER');
export const resolveDeviceFingerprint = createAction('userEnvironment/RESOLVE_DEVICE_FINGERPRINT');
export const setDeviceFingerprint = createAction('userEnvironment/SET_DEVICE_FINGERPRINT');
export const setDeviceFingerprintFailure = createAction('userEnvironment/SET_DEVICE_FINGERPRINT_FAILURE');
export const emitPageView = createAction('userEnvironment/EMIT_PAGE_VIEW');
export const emitUserInteraction = createAction('userEnvironment/EMIT_USER_INTERACTION');
export const emitScrollTracking = createAction('userEnvironment/EMIT_SCROLL_TRACKING');
