import { LOCATION_CHANGE } from 'connected-next-router';
import { createLogic } from 'redux-logic';
import get from 'lodash/get';
import { setBookingChannel } from 'store/checkout/checkoutActions';
import { LUXE_BRAND_BOOKING_CHANNEL, HOTELS_BRAND_BOOKING_CHANNEL, LUXE_HOST } from 'config';
import { getBookingChannel } from 'store/checkout/checkoutSelectors';

const isLuxeReferrer = () => (window?.document?.referrer ?? '').startsWith(LUXE_HOST);

export const bookingChannelLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,
  async process({ action, getState }, dispatch, done) {
    const { isFirstRendering } = action.payload;
    const state = getState();
    const pathName = get(state, 'router.location.pathname');
    const previousBookingChannel = getBookingChannel(state);

    // We only want to set the booking channel as luxe if we've landed directly on the checkout
    // with luxe as the referrer (hence isFirstRendering), otherwise treat as hotels
    const defaultBookingChannel = isFirstRendering && isLuxeReferrer() ? LUXE_BRAND_BOOKING_CHANNEL : HOTELS_BRAND_BOOKING_CHANNEL;
    const channel = previousBookingChannel || defaultBookingChannel;

    // If the user arrived at confirmation from checkout, do not change the booking channel
    if (!pathName.startsWith('/bookings') || !previousBookingChannel) {
      dispatch(setBookingChannel(channel));
    }

    done();
  },
});
