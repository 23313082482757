import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchMemberFavourites = async ({ accessToken, memberId, qhUserId }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/members/${memberId}/favourites`,
    method: 'GET',
    accessToken,
    qhUserId,
  });

  return response.data?.favourites ?? [];
};
