import React, { useMemo } from 'react';
import { GlobalHeader } from '../GlobalHeader';
import { useDataLayer } from 'hooks/useDataLayer';
import { useLoginUrl } from 'lib/qffAuth';
import {
  getTitle,
  getFirstName,
  getLastName,
  getMembershipTier,
  getPointsBalance,
  getMemberId,
  getHasDismissedLoginTooltip,
} from 'store/user/userSelectors';
import { dismissLoginTooltip } from 'store/user/userActions';
import { getDeviceType } from 'store/userEnvironment/userEnvironmentSelectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDesktopNavigationMenu,
  PHONE_NAVIGATION_MENU,
  QFF_LINKS,
  SIMPLICITY_HEADER_ENABLED,
  QFF_ACCOUNT_MANAGEMENT,
  ACCOUNT_MANAGEMENT_TYPES,
  HEADER_LOGO,
  QFF_AUTH_API_URL,
} from 'config';
import { getDesktopNavigationMenuGclid } from 'config/navigationMenuGclid.qantas';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import { useQueryString } from 'hooks/useQueryString';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useRouter } from 'next/router';
import { useIsAuthenticated, useLogout } from 'lib/oauth';
import { getGclid } from 'components/HomePage/sessionStorage';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

const Header = () => {
  const { emitInteractionEvent } = useDataLayer();
  const dispatch = useDispatch();
  const device = useSelector(getDeviceType);
  const isMobileApp = useSelector(getIsMobileApp);
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobile = isLessThanBreakpoint(0);
  const MobileOrDesktop = isMobile ? 'Mobile' : 'Desktop';
  const title = useSelector(getTitle);
  const memberId = useSelector(getMemberId);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const membershipTier = useSelector(getMembershipTier);
  const pointsBalance = useSelector(getPointsBalance);
  const isAuthenticated = useIsAuthenticated();
  const hasDismissedLoginTooltip = useSelector(getHasDismissedLoginTooltip);
  const { pathname } = useRouter();
  const displayLoginTooltip = QFF_ACCOUNT_MANAGEMENT === ACCOUNT_MANAGEMENT_TYPES.APP_WIDE && !hasDismissedLoginTooltip;
  const isMapPage = pathname === '/search/map';
  const isHomePage = pathname === '/';
  const gclid = getGclid();
  const { menuClickEvent } = useMenuClickEvent();

  const { loginUrl } = useLoginUrl();
  const { logout } = useLogout();

  const arrowDirection = useMemo(() => [isMobileApp ? 'none' : 'up', 'right'], [isMobileApp]);

  const authData = {
    isAuthenticated,
    title,
    firstName,
    lastName,
    memberId,
    membershipTier,
    pointsBalance,
  };

  const queryString = useQueryString();

  const handleDismissTooltip = () => {
    dispatch(dismissLoginTooltip());
    emitInteractionEvent({ type: 'Login tooltip', value: `${device} LoginTooltip Dismissed` });
  };

  const onClickLogin = () => {
    emitInteractionEvent({ type: 'QFF Login Button', value: `${MobileOrDesktop} Log In Button Selected` });
    menuClickEvent({ itemText: `${MobileOrDesktop} QFF Login Button`, url: QFF_AUTH_API_URL });
    window.location.href = loginUrl;
  };

  const onClickLogout = () => {
    emitInteractionEvent({ type: 'QFF Logout Button', value: `${MobileOrDesktop} Log Out Button Selected` });
    menuClickEvent({ itemText: `${MobileOrDesktop} QFF Logout Button`, url: QFF_AUTH_API_URL });
    logout();
  };

  const addGclidParam = gclid && isHomePage;

  return (
    <>
      <GlobalHeader
        datatestId="global-header"
        desktopLinks={addGclidParam ? getDesktopNavigationMenuGclid(gclid) : getDesktopNavigationMenu()}
        phoneLinks={PHONE_NAVIGATION_MENU()}
        qffLinks={QFF_LINKS}
        qffAuthData={authData}
        onInteraction={emitInteractionEvent}
        onClickLogin={onClickLogin}
        onClickLogout={onClickLogout}
        onDismissLoginTooltip={handleDismissTooltip}
        queryString={queryString}
        displayLoginTooltip={displayLoginTooltip && !isMapPage}
        qffLoginEnabled={QFF_ACCOUNT_MANAGEMENT === ACCOUNT_MANAGEMENT_TYPES.APP_WIDE}
        universalNavEnabled={SIMPLICITY_HEADER_ENABLED}
        navEnabled={!isMobileApp}
        arrowDirection={arrowDirection}
        {...HEADER_LOGO}
      />
    </>
  );
};

export default Header;
