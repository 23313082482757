import * as actions from 'store/faqs/faqActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchContent';
import { createAsyncLogic } from 'lib/logic';

export const fetchFaqLogic = createAsyncLogic({
  type: actions.fetchFaqs,
  latest: true,
  async process({ action }, dispatch) {
    const { payload } = action;
    const faqs = await client.fetchContent({ ...payload, type: 'faqs' });
    dispatch(actions.setFaqs(faqs));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});
