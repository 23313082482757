import * as sessionStorage from 'lib/browser/sessionStorage';

export const registerGclid = (gclid) => {
  sessionStorage.set('gclid', gclid);
};

export const getGclid = () => {
  return sessionStorage.get('gclid');
};

export const clearGclid = () => {
  sessionStorage.set('gclid', null);
};
