import { createReducer } from '@reduxjs/toolkit';
import { setIpAddress, setBrowser, setDeviceFingerprint, setDeviceFingerprintFailure, setIsBot } from './userEnvironmentActions';

export const initialState = {
  ipAddress: null,
  deviceFingerprint: null,
  browser: {
    name: null,
    version: null,
    type: null,
    userAgent: null,
  },
  isBot: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setIpAddress, (state, { payload }) => {
      state.ipAddress = payload;
    })

    .addCase(setBrowser, (state, { payload }) => {
      state.browser = payload;
    })

    .addCase(setIsBot, (state, { payload }) => {
      state.isBot = payload;
    })

    .addCase(setDeviceFingerprint, (state, { payload }) => {
      state.deviceFingerprint = payload;
    })

    .addCase(setDeviceFingerprintFailure, (state, { payload }) => {
      state.deviceFingerprintError = payload;
    }),
);
