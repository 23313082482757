import { createReducer } from '@reduxjs/toolkit';
import { setCampaign, clearCampaign } from './campaignActions';

const initialState = {
  campaign: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setCampaign, (state, { payload }) => {
      state.campaign = payload;
    })
    .addCase(clearCampaign, (state) => {
      state.campaign = null;
    }),
);
