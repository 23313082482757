import wrappedAxios from './wrappedAxios';

import { HOTELS_API_URL } from 'config';

export const createEnquiry = async ({ payload }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/enquiries`,
    method: 'post',
    data: payload,
  });

  return response.data;
};
