import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchSiteMessage = async ({ pageName }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/site-message`,
    method: 'get',
    params: {
      pageName,
    },
  });

  return response.data;
};
