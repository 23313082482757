import isString from 'lodash/isString';
import {
  format as dateFnsFormat,
  parseISO as parse,
  addDays,
  subDays,
  startOfToday,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  differenceInDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from 'date-fns';
import { SEARCH_DATE_FORMAT } from 'config';

export const format = (date, format = SEARCH_DATE_FORMAT) => {
  const parsedDate = typeof date === 'string' ? parse(date) : date;
  const formattedDate = dateFnsFormat(parsedDate, format);
  return isString(formattedDate) ? formattedDate : null;
};

export {
  parse,
  addDays,
  subDays,
  startOfToday,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  differenceInDays,
  differenceInCalendarDays,
  differenceInCalendarMonths,
};
