import keyBy from 'lodash/keyBy';
import { createSelector } from '@reduxjs/toolkit';
import { getRoomTypesWithoutOffers as getAllRoomTypes } from 'store/property/propertySelectors';
import { getAvailableRoomTypes } from './propertyAvailabilitySelectors';

export const getUnavailableRoomTypes = createSelector(getAllRoomTypes, getAvailableRoomTypes, (allRoomTypes, availableRoomTypes) => {
  const availableById = keyBy(availableRoomTypes, (room) => room.id);
  const unavailableRoomTypes = [];

  allRoomTypes.forEach((room) => {
    if (!availableById[room.id]) {
      unavailableRoomTypes.push(room);
    }
  });

  return unavailableRoomTypes;
});
