import { isDate } from 'date-fns';
import { LocalDate, Today, isBefore, isSameDay } from 'lib/localDate';
import { createSelector } from '@reduxjs/toolkit';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { getPropertyId, getPropertyLocation } from 'store/property/propertySelectors';
import {
  querySelectors,
  getFullKnownQuery,
  getQueryCheckIn,
  getQueryCheckOut,
  getQueryAdults,
  getQueryChildren,
  getQueryInfants,
  getQueryPayWith,
} from 'store/router/routerSelectors';

const getCheckoutPageQuery = createSelector(
  getQueryCheckIn,
  getQueryCheckOut,
  getQueryAdults,
  getQueryChildren,
  getQueryInfants,
  getQueryPayWith,
  (checkIn, checkOut, adults, children, infants, payWith) => ({ adults, children, infants, checkIn, checkOut, payWith }),
);

export const getCheckoutLinkQueryString = createSelector(getCheckoutPageQuery, stringifyQueryValues);

export const getSearchLinkQuery = createSelector(getFullKnownQuery, getPropertyLocation, (fullQuery, propertyLocation) => {
  const location = fullQuery.location || propertyLocation;
  return { ...fullQuery, location };
});

const hasValidCheckInDate = (query) => {
  // we had an issue where crawlers don't respect route changes. This is causing the property
  // page to fetch availability with invalid dates.
  if (!isDate(query.checkIn)) return false;

  const checkIn = LocalDate.fromDate(query.checkIn);

  return isBefore(Today(), checkIn) || isSameDay(Today(), checkIn);
};

export const getHasValidQuery = createSelector(
  getPropertyId,
  querySelectors.checkIn,
  querySelectors.checkOut,
  querySelectors.adults,
  (propertyId, checkIn, checkOut, adults) => {
    return !!propertyId && hasValidCheckInDate({ checkIn }) && !!checkOut && adults > 0;
  },
);
