import { createSelector } from '@reduxjs/toolkit';
import { getRecommendedPropertyAvailability } from './getRecommendedPropertyAvailability';
import buildPropertyCardsData from 'lib/search/buildPropertyCardsData';
import { getPropertyId } from 'store/property/propertySelectors';

export const getRecommendedPropertyAvailabilityCardsData = createSelector(getRecommendedPropertyAvailability, getPropertyId, (results) => {
  const firstThreeProperties = results.slice(0, 3);

  return buildPropertyCardsData({ results: firstThreeProperties });
});
