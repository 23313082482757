import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const addMemberFavourite = async ({ accessToken, favourite, memberId, qhUserId }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/members/${memberId}/favourites`,
    data: favourite,
    method: 'POST',
    accessToken,
    qhUserId,
  });
  return response.data;
};
