import { createReducer } from '@reduxjs/toolkit';
import has from 'lodash/has';
import { setTreatments, setIsVip, registerSplits } from './splitActions';

export const initialState = {
  treatments: {},
  isVip: false,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setTreatments, (state, { payload }) => {
      state.treatments = payload;
    })

    .addCase(setIsVip, (state, { payload }) => {
      state.isVip = payload;
    })

    .addCase(registerSplits, (state, { payload }) => {
      const treatments = { ...state.treatments };

      payload.forEach((key) => {
        if (!has(treatments, key)) treatments[key] = null;
      });

      state.treatments = treatments;
    }),
);
