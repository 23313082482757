import { colors } from './colors';

export const slider = {
  rail: {
    height: '4px',
    background: colors.greys.alto,
  },
  track: {
    background: colors.brand.primary,
    height: '4px',
  },
  handle: {
    border: `2px solid ${colors.brand.primary}`,
    background: colors.brand.primary,
    width: '18px',
    height: '18px',
    top: '4px',
  },
};
