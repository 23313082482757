import { getValueAndUnit } from 'polished';
import isNumber from 'lodash/isNumber';
import memoize from 'lodash/memoize';

export const is = (n) => n !== undefined && n !== null;
export const px = (n) => (isNumber(n) ? n + 'px' : n);

// assuming root font-size is 16px
const ONE_PX_IN_REM = 0.0625;

const convertPxToRem = (px) => px * ONE_PX_IN_REM;

export const minusNthPx = (unitInPx) =>
  memoize((value) => {
    const [dimension, unit] = getValueAndUnit(value, true);
    switch (unit) {
      case 'em':
      case 'rem':
        return `${dimension - convertPxToRem(unitInPx)}${unit}`;
      case 'px':
        return `${dimension - unitInPx}px`;
      default:
        return value;
    }
  });

export const minusOnePx = minusNthPx(1);
export const minusTwoPx = minusNthPx(2);
