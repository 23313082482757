import * as actions from 'store/exclusiveOffer/exclusiveOfferActions';
import * as client from 'lib/clients/fetchExclusiveOfferList';
import { createAsyncLogic } from 'lib/logic';

export const fetchExclusiveOfferListLogic = createAsyncLogic({
  type: actions.fetchExclusiveOfferList,
  latest: true,
  async process(_, dispatch) {
    const exclusiveOfferList = await client.fetchExclusiveOfferList();
    dispatch(actions.setExclusiveOfferList(exclusiveOfferList));
  },
});
