/* eslint-disable no-console */
import * as campaignsClient from 'lib/clients/fetchCampaign';
import { fetchCampaign, setCampaign, clearCampaign } from './campaignActions';
import { getTreatments } from 'store/split/splitSelectors';
import isEmpty from 'lodash/isEmpty';
import { createAsyncLogic } from 'lib/logic';
import { VPP_CAMPAIGN, DEFAULT_CAMPAIGN } from 'config';

const fetchCampaignLogic = createAsyncLogic({
  type: fetchCampaign,
  latest: true,
  async process({ getState }, dispatch) {
    const campaign = await campaignsClient.fetchCampaign();

    if (!isEmpty(campaign)) {
      dispatch(setCampaign(campaign));
    } else {
      const treatments = getTreatments(getState());
      const vpp = treatments['vpp'];

      if (vpp?.treatment === 'on') {
        dispatch(setCampaign(VPP_CAMPAIGN));
      } else {
        dispatch(setCampaign(DEFAULT_CAMPAIGN));
      }
    }
  },
  onError(_, dispatch) {
    dispatch(clearCampaign());
  },
});

export const logic = [fetchCampaignLogic];
