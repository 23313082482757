import { createLogic } from 'redux-logic';
import updateRouteQuery from 'lib/routing/updateRouteQuery';
import { updateQuery } from 'store/propertyAvailability/propertyAvailabilityActions';

export const updateQueryLogic = createLogic({
  type: updateQuery,
  async process({ action, getState }, dispatch, done) {
    updateRouteQuery({ state: getState(), newQuery: action.payload, dispatch });

    done();
  },
});
