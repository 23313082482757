import { fetchBooking, fetchBookingFailure, setBooking } from 'store/booking/bookingActions';
import { getQhUserId } from 'store/user/userSelectors';
import { fetchBookingById } from 'lib/clients/fetchBookingById';
import { createAsyncLogic } from 'lib/logic';

export const fetchBookingLogic = createAsyncLogic({
  type: fetchBooking,
  latest: true,
  process: async ({ action, getState }, dispatch) => {
    const { payload: bookingId } = action;
    const qhUserId = getQhUserId(getState());
    const booking = await fetchBookingById({ bookingId, qhUserId });
    dispatch(setBooking(booking));
  },
  onError: ({ parsedError }, dispatch) => {
    dispatch(fetchBookingFailure(parsedError));
  },
});
