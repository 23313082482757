import * as client from 'lib/clients/createQuote';
import * as actions from 'store/quoteDepositPay/quoteDepositPayActions';
import { getQuoteDepositPayQuery } from 'store/quoteDepositPay/quoteDepositPaySelectors/getQuoteDepositPayQuery';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { createAsyncLogic } from 'lib/logic';

export const createQuoteDepositPayLogic = createAsyncLogic({
  type: actions.createQuoteDepositPay,
  latest: true,
  async process({ getState }, dispatch) {
    const state = getState();
    const payload = getQuoteDepositPayQuery(state);
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const paidByDeposit = 'true';
    const { quote } = await client.createQuote({ payload, accessToken, flightBookerToken, qhUserId, paidByDeposit });

    // It's important that the levels are set before the quote
    // so that usePointsAmountToAllocate, which depends on the levels being available
    // on initialization has the correct levels for conversion.
    // That logic is only allowed to execute once so if it triggers with the
    // wrong values, bad things happen.
    // TODO: consider a better approach this.

    dispatch(actions.createQuoteDepositPaySuccess({ quote }));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.createQuoteDepositPayFailure(parsedError));
  },
});
