import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { parse as parseQueryString } from 'query-string';
import { getQueryString } from './routerGetters';
import { getSearchMeta } from 'store/search/searchSelectors/searchSelectors';

export const getQueryParams = createSelector([getQueryString, getSearchMeta], (queryString, meta) => {
  if (isEmpty(queryString) && meta?.query) {
    return meta.query;
  } else {
    return parseQueryString(queryString);
  }
});
