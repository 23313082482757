import { createLogic } from 'redux-logic';
import updateRouteQuery from 'lib/routing/updateRouteQuery';
import { clearClient } from '../uiActions';

export const clearClientLogic = createLogic({
  type: clearClient,
  async process({ getState }, dispatch, done) {
    const newQuery = { client: null };
    updateRouteQuery({ state: getState(), newQuery, dispatch });
    done();
  },
});
