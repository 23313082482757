import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Heading, Image, Box } from '@qga/roo-ui/components';
import { alertIllustration } from '@qga/roo-ui/assets';

const ErrorUI = ({ heading, children }) => (
  <Flex py={[8, 16]} px={8} flexDirection="column" alignItems="center" textAlign="center">
    <Image my={8} width="60px" src={alertIllustration} alt="alert icon" />
    <Heading.h1 fontSize="xl">{heading}</Heading.h1>
    <Box data-testid="error-body">{children}</Box>
  </Flex>
);

ErrorUI.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorUI;
