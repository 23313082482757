import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL, IS_SERVER } from 'config';

type Props = { accessToken?: string; memberId: string };

const fetchMemberDetails = async ({ memberId, accessToken }: Props) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/member-details/${memberId}`,
    method: 'get',
    timeout: IS_SERVER ? 2000 : 10000,
    accessToken,
  });

  return response.data;
};

export default fetchMemberDetails;
