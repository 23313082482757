import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Icon, Text } from '@qga/roo-ui/components';
import { fetchSiteMessage } from 'store/siteMessage/siteMessageActions';
import { getSiteMessage } from 'store/siteMessage/siteMessageSelectors';
import { getPageName } from 'store/router/routerSelectors';
import Visibility from 'lib/browser/visibility';

const POLLING_INTERVAL = 10000;

const PollingSiteMessage = React.memo(() => {
  const message = useSelector(getSiteMessage);
  const pageName = useSelector(getPageName);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = () => dispatch(fetchSiteMessage());

    fetch();

    const pollerRef = Visibility.every(POLLING_INTERVAL, fetch);

    return () => Visibility.stop(pollerRef);
  }, [pageName]); // eslint-disable-line react-hooks/exhaustive-deps
  // This is a componentDidMount case. We need to run this hook once per page.

  if (!message) return null;
  return (
    <Box p={3} textAlign="center" bg="ui.errorBackground" fontSize="sm">
      <Icon name="warning" color="ui.error" mr={3} />
      <Text>{message}</Text>{' '}
    </Box>
  );
});

PollingSiteMessage.displayName = 'PollingSiteMessage';

export default PollingSiteMessage;
