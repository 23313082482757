import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchPropertyDetails = async ({ propertyId, qhUserId, cookie }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/properties/${propertyId}`,
    method: 'get',
    qhUserId,
    cookie,
  });

  return response.data;
};
