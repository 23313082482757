export const BOOKING_STATES = {
  SUBMITTING: 'submitting',
  ACCEPTING: 'accepting',
  BOOKING: 'booking',
  BOOKED: 'booked',
  BLOCKED: 'blocked',
  FAILED: 'failed',
  POLLING_FAILED: 'polling_failed',
};

export const BOOKING_ERROR_CODES = {
  GENERAL_ERROR: 'general_error', // generated internally in lieu of an error supplied by the bookings api
  PRICE_INCREASE: 'inventory_price_changed', // price has gone up since quoted
  INVENTORY_UNAVAILABLE: 'hotel_reservation_failed_inventory_unavailable',
  PAYMENT_FAILED: 'payment_failed', // general payment failure not attributable to any specific payment method. Indicative of fraud deny
  PAYMENT_MISMATCH: 'payment_price_mismatch', //payments supplied don't match the booking total
  VALIDATION_ERROR: 'command_validation_error', //bookings has failed validation of the payload. Reason is provided in `message` attribute of the error.
  PAYMENT_FAILED_POINTS_TOKEN_NOT_PRESENT: 'payment_failed_points_token_not_present', // generated internally when we detect a points payment being made after the access token has expired. This is an edge condition that shouldn't happen but under certain conditions we don't seem to be notified by the qff library that the token has expired.
};
