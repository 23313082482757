import { IS_PRODUCTION } from 'config';
import { createReducer } from '@reduxjs/toolkit';
import { setBooking, fetchBookingFailure, clearBooking, showNpsSurveyAfterBooking } from './bookingActions';

const initialState = {
  error: null,
  booking: null,
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setBooking, (state, { payload }) => {
      state.booking = payload;
    })
    .addCase(clearBooking, (state) => {
      state.booking = null;
    })
    .addCase(fetchBookingFailure, (state, { payload }) => {
      state.booking = null;
      state.error = payload;
    })
    .addCase(showNpsSurveyAfterBooking, (state) => {
      state.booking.showNpsSurveyAfterBooking = IS_PRODUCTION;
    });
});
