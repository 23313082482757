import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import queryValuesToString from 'lib/routing/queryValuesToString';

export const searchLocationDeals = async ({
  location,
  checkIn,
  checkOut,
  adults,
  children,
  infants,
  sortBy,
  accessToken,
  flightBookerToken,
  limit,
  qhUserId,
}) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/deals`,
    method: 'get',
    params: queryValuesToString({
      location,
      checkIn,
      checkOut,
      adults,
      children,
      infants,
      limit,
      flightBookerToken,
      sortBy,
    }),
    accessToken,
    qhUserId,
  });

  return response.data;
};
