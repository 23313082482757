import { getPageFromState } from './helpers';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { mapRedoSearchClick } from 'store/search/searchActions';

const emitMapRedoSearchClickEvent = (_, prevState) => {
  const page = getPageFromState(prevState);

  return interactionEvent({ type: 'Redo Search Button', value: 'Redo Selected', page: page.name });
};

export default { [mapRedoSearchClick]: emitMapRedoSearchClickEvent };
