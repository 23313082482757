import { EMAIL_ENQUIRY_TYPES } from 'lib/enums/contactUs';

const createEnquiryPayload = (formData = {}) => {
  const { enquiryType } = formData;
  const mappedEnquiryType = EMAIL_ENQUIRY_TYPES[enquiryType]?.value;

  return {
    ...formData,
    enquiryType: mappedEnquiryType,
  };
};

export default createEnquiryPayload;
