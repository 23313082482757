import { createSelector } from '@reduxjs/toolkit';
import { getRecommendedPropertyAvailability } from './getRecommendedPropertyAvailability';
import { getPropertyId } from 'store/property/propertySelectors';
import reduce from 'lodash/reduce';

export const getGa4Properties = createSelector(getRecommendedPropertyAvailability, getPropertyId, (results) => {
  const firstThreeProperties = results.slice(0, 3);

  const properties = reduce(
    firstThreeProperties,
    (accum, item) => {
      const mappedItem = {
        property: { ...item?.property, hasOffer: item?.offer?.promotion?.name ? true : false },
        roomType: item.roomType,
        offer: {
          charges: {
            total: item?.offer?.charges?.total,
            totalCash: item?.offer?.charges?.totalCash ? item?.offer?.charges?.totalCash : { amount: '0', currency: 'AUD' },
          },
        },
      };
      return [...accum, mappedItem];
    },
    [],
  );

  return properties;
});
