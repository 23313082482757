import { HOTELS_API_URL } from 'config';
import { safeAxios } from './safeAxios';

export const trackInteractionEvent = async ({ impression = [], ...payload }, requestOptions) => {
  await safeAxios({
    ...requestOptions,
    url: `${HOTELS_API_URL}/personalisation/interactions`,
    method: 'post',
    data: {
      ...payload,
      impression: impression.length ? impression : undefined,
    },
  });
};

// user is resolved from access token
export const updateUser = async ({ accessToken }) => {
  await safeAxios({
    accessToken,
    url: `${HOTELS_API_URL}/personalisation/users`,
    method: 'post',
  });
};
