import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

export const getProperty = (state) => get(state, 'property.property');
export const getPropertyId = (state) => {
  const match = get(state, 'router.location.pathname', '').match(/\/properties\/([0-9]*)/);
  return match ? match[1] : null;
};

export const getPropertyLocation = (state) => get(state, 'property.property.regionFullName');
export const getPropertyName = createSelector(getProperty, (property) => `${property?.name}`);
export const getPropertyNameAndLocation = createSelector(
  getProperty,
  (property) => `${property?.name}, ${property?.address?.suburb}, ${property?.address?.country}`,
);

export const getRequestCallbackModalOpen = (state) => get(state, 'property.isRequestCallbackModalOpen');
export const getIsLoading = (state) => !!get(state, 'property.isLoading');
export const getIsExclusive = (state) => !!get(state, 'property.isExclusive');
export const getError = (state) => get(state, 'property.error');
export const getTripAdvisorRating = createSelector(getProperty, (property) =>
  (property?.customerRatings || []).find((rating) => rating.source === 'trip_advisor'),
);

export const getRoomTypesWithoutOffers = (state) => get(state, 'property.property.roomTypes');
export const getCountry = createSelector(getProperty, (property) => property?.address?.country);

export const getIsDomestic = createSelector(getCountry, (country) => country === 'Australia');

export const getGa4AvailableProperty = createSelector(getProperty, (property) => {
  const mappedProperty = {
    id: property?.id,
    name: property?.name,
    category: property?.category,
    rating: property?.rating,
    imageCount: property?.images?.length,
    facilities: property?.propertyFacilities.join(', '),
    internationalOrDomestic: property?.address?.country === 'Australia' ? 'domestic' : 'international',
  };
  return mappedProperty;
});
