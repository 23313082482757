import { trackEvent } from 'store/split/splitActions';
import { createAsyncLogic } from 'lib/logic';

export const trackEventLogic = createAsyncLogic({
  type: trackEvent,
  async process({ action, splitClient }) {
    const { name, value, properties } = action.payload;
    await splitClient.track(name, value, properties);
  },
});
