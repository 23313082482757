import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const removeMemberFavourite = async ({ accessToken, favouriteId, memberId, qhUserId }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/members/${memberId}/favourites/${favouriteId}/delete`,
    data: {},
    method: 'POST',
    accessToken,
    qhUserId,
  });

  return response.data;
};
