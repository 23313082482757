import wrappedAxios from './wrappedAxios';
import { PUBLIC_PATH } from 'config';

export const fetchAppVersion = async (props = {}) => {
  const response = await wrappedAxios({
    ...props,
    url: `${PUBLIC_PATH}appVersion.json`,
    method: 'get',
  });

  return response.data.version;
};
