import { createSelector } from '@reduxjs/toolkit';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { omitEmpty } from 'lib/object';
import { DEFAULT_FILTERS } from 'lib/enums/search';
import { getFullKnownQuery } from 'store/router/routerSelectors';

export const getFilterCount = createSelector(getFullKnownQuery, (query) => {
  const filters = pick(query, keys(DEFAULT_FILTERS));
  return keys(omitEmpty(filters)).length;
});
