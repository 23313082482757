import { fetchPointsBurnTiers } from 'lib/clients/fetchPointsBurnTiers';
import * as actions from 'store/pointsBurnTiers/pointsBurnActions';
import { createAsyncLogic } from 'lib/logic';
import { apiRequestFailure } from 'store/error/errorActions';

export const fetchPointsBurnTiersLogic = createAsyncLogic({
  type: actions.fetchPointsBurnTiers,
  latest: true,
  async process(_, dispatch) {
    const pointsTierInstance = await fetchPointsBurnTiers();
    if (pointsTierInstance?.levels && pointsTierInstance?.name) {
      dispatch(actions.setPointsBurnTiers(pointsTierInstance));
    }
  },
  onError({ parsedError }, dispatch) {
    dispatch(apiRequestFailure(parsedError));
  },
});
