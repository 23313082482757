import { getPageFromState } from './helpers';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { emitUserInteraction } from 'store/userEnvironment/userEnvironmentActions';

const emitUserInteractionEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  const { type, value, customAttributes } = action.payload;

  return interactionEvent({ type, value, page: page.name, customAttributes });
};

export default { [emitUserInteraction]: emitUserInteractionEvent };
