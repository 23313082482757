import { LocalDate } from './localDate';
import { toLocalDate } from './dateConversion';

export const isBefore = (firstDate, secondDate) => {
  firstDate = toLocalDate(firstDate).toEpochDay();
  secondDate = toLocalDate(secondDate).toEpochDay();
  return firstDate < secondDate;
};

export const isSameDay = (firstDate, secondDate) => {
  firstDate = toLocalDate(firstDate).toEpochDay();
  secondDate = toLocalDate(secondDate).toEpochDay();
  return firstDate === secondDate;
};

export const isWithinRange = (date, dateRange = {}) => {
  date = toLocalDate(date).toEpochDay();
  const startDate = toLocalDate(dateRange.start).toEpochDay();
  const endDate = toLocalDate(dateRange.end).toEpochDay();

  if (!(startDate <= endDate)) {
    throw new RangeError('Start date should be before end date');
  }

  return date >= startDate && date <= endDate;
};

export const differenceInDays = (leftDate, rightDate) => {
  leftDate = toLocalDate(leftDate).toEpochDay();
  rightDate = toLocalDate(rightDate).toEpochDay();
  return leftDate - rightDate;
};

// This does not factor days into the calculation so the difference between 2019-01-31 and 2019-02-01
// is still a month event though less than 30 days has elapsed.
export const differenceInMonths = (leftDate, rightDate) => {
  leftDate = toLocalDate(leftDate);
  rightDate = toLocalDate(rightDate);

  const yearDiff = leftDate.year - rightDate.year;
  const monthDiff = leftDate.month - rightDate.month;
  return yearDiff * 12 + monthDiff;
};

// This factors days into the calculation so a full month has to elapsed before it is counted.
export const differenceInFullMonths = (leftDate, rightDate) => {
  leftDate = toLocalDate(leftDate);
  rightDate = toLocalDate(rightDate);

  if (leftDate.toEpochDay() === rightDate.toEpochDay()) {
    return 0;
  }

  const sign = Math.sign(leftDate.toEpochDay() - rightDate.toEpochDay());
  const firstDate = LocalDate.fromEpochDay(Math.min(leftDate.toEpochDay(), rightDate.toEpochDay()));
  const lastDate = LocalDate.fromEpochDay(Math.max(leftDate.toEpochDay(), rightDate.toEpochDay()));

  const isFullMonth = firstDate.day <= lastDate.day;
  const fullMonthAdjustment = isFullMonth ? 0 : -1;
  const initialDiff = Math.abs(differenceInMonths(leftDate, rightDate));
  const adjustedDiff = initialDiff + fullMonthAdjustment;

  return adjustedDiff === 0 ? 0 : adjustedDiff * sign;
};
