import { createReducer } from '@reduxjs/toolkit';
import { setSiteMessage, clearSiteMessage } from './siteMessageActions';

const initialState = {
  message: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setSiteMessage, (state, { payload }) => {
      state.message = payload.message;
    })
    .addCase(clearSiteMessage, (state) => {
      state.message = null;
    }),
);
