import get from 'lodash/get';
import { getPageFromState } from './helpers';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { dismissSearchMessaging } from 'store/user/userActions';

const emitDismissSearchMessagingEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  const type = get(action, 'payload.type', 'Member Deal Banner');

  return interactionEvent({ type, value: 'Banner Dismissed', page: page.name });
};

export default { [dismissSearchMessaging]: emitDismissSearchMessagingEvent };
