import * as personalise from 'lib/clients/personalise';
import * as sessionStorage from 'lib/browser/sessionStorage';
import { getAccessToken } from 'store/user/userSelectors';
import { updateUser } from '../personalisationActions';
import { createAsyncLogic } from 'lib/logic';

export const SESSION_STORAGE_KEY = 'perso_user_updated';

export const updateUserLogic = createAsyncLogic({
  type: updateUser,
  async process({ getState }) {
    if (!sessionStorage.get(SESSION_STORAGE_KEY)) {
      const accessToken = getAccessToken(getState());
      await personalise.updateUser({ accessToken });
      sessionStorage.set(SESSION_STORAGE_KEY, new Date());
    }
  },
});
