import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { useMount } from 'react-use';
import { getTreatments } from 'store/split/splitSelectors';
import { registerSplits } from 'store/split/splitActions';
import isArray from 'lodash/isArray';

/*
 * Has two modes for use:
 * 1. By passing children as a react node that will be rendered if the split treatment matches.
 *    Splits are registered immediately during component mounting which will result in a Split.IO impression
 *    in the split api at this time.
 *    Eg: <TreatmentToggle split="my_toggle" treatment="on">Toggle is on!</TreatmentToggle>
 *
 * 2. By passing a render prop function as children that is passed `hasTreatment` as a function that
 *    can be lazily evaluated within render. This enables Split.IO impressions to be be lazily evaluate once another
 *    condition has been met so that they are only registered once a user is actually exposed to a certain
 *    ui feature such as when a modal has been opened.
 *    Eg:
 *    <TreatmentToggle split="my_toggle" treatment="on">
 *    {(hasTreatment) => (
 *      { isModalShowing && hasTreatment() && <Box>Modal is open and toggle is on</Box>
 *    )}
 *    </TreatmentToggle>
 */

const TreatmentToggle = (props) => {
  const { treatment, split, children } = props;
  const splitTreatments = useSelector(getTreatments);
  const dispatch = useDispatch();
  const treatments = isArray(treatment) ? treatment : [treatment];

  useMount(() => {
    if (!isFunction(children)) {
      dispatch(registerSplits([split]));
    }
  });

  if (isFunction(children)) {
    return children({
      hasTreatment: () => {
        dispatch(registerSplits([split]));
        return treatments.includes(get(splitTreatments, [split, 'treatment']));
      },
    });
  } else {
    return treatments.includes(get(splitTreatments, [split, 'treatment'])) ? children : null;
  }
};

TreatmentToggle.defaultProps = {
  children: null,
  treatment: null,
};

TreatmentToggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  split: PropTypes.string.isRequired,
  treatment: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(String)]),
};

export default TreatmentToggle;
