import * as actions from 'store/ui/uiActions';
import * as client from 'lib/clients/fetchAppVersion';
import { getAppVersion } from 'store/ui/uiSelectors';
import { createAsyncLogic } from 'lib/logic';

export const fetchAppVersionLogic = createAsyncLogic({
  type: actions.fetchAppVersion,
  latest: true,
  async process({ getState }, dispatch) {
    const state = getState();

    const currentVersion = getAppVersion(state);
    const deployedVersion = await client.fetchAppVersion();

    const appVersion = currentVersion ?? deployedVersion;
    const appOutOfDate = !!appVersion && appVersion !== deployedVersion;

    if (!currentVersion || appOutOfDate) {
      dispatch(actions.setAppVersion({ appVersion, appOutOfDate }));
    }
  },
});
