import { createSelector } from '@reduxjs/toolkit';
import reject from 'lodash/reject';
import { getPropertyId } from 'store/property/propertySelectors';

const getRecommendedPropertyAvailabilityResults = (state) => state?.recommendedProperty?.results || [];

export const getRecommendedPropertyAvailability = createSelector(
  getRecommendedPropertyAvailabilityResults,
  getPropertyId,
  (results, propertyId) => reject(results, ['property.id', propertyId]),
);
