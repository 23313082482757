export const loadScript = async ({ url, maxRetries = 3 }) => {
  const attemptScriptLoad = (resolve, reject, retries = 0) => {
    if (retries < maxRetries) {
      const newScript = document.createElement('script');

      newScript.onerror = () => {
        attemptScriptLoad(resolve, reject, retries + 1);
      };

      newScript.onload = resolve;

      document.body.appendChild(newScript);

      let queryString = '';
      if (retries > 0) {
        queryString = '?retries=' + retries; //ensure a unique url to prevent caching of previous retries
      }

      newScript.src = `${url}${queryString}`;
    } else {
      const error = new Error(`Unable to load ${url} script after ${maxRetries} retries`);
      reject(error);
    }
  };

  return new Promise(attemptScriptLoad);
};
