import { createSelector } from '@reduxjs/toolkit';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { getAvailableRoomTypes } from './propertyAvailabilitySelectors';
import { getQueryFeaturedOfferId } from 'store/router/routerSelectors';

export const getSortedAvailableRoomTypes = createSelector(getAvailableRoomTypes, getQueryFeaturedOfferId, (roomTypes, featuredOfferId) => {
  if (!featuredOfferId) return roomTypes;

  const roomTypeWithFeaturedOffer = find(roomTypes, (roomType) => {
    const offerIds = map(roomType.offers, 'id');

    return offerIds.includes(featuredOfferId);
  });

  const sortedRoomTypes = sortBy(roomTypes, (roomType) => roomType?.id !== roomTypeWithFeaturedOffer?.id);
  const sortedRoomTypesAndOffers = map(sortedRoomTypes, (roomType) => {
    const sortedOffers = sortBy(roomType.offers, (offer) => offer?.id !== featuredOfferId);

    return {
      ...roomType,
      offers: sortedOffers,
    };
  });

  return sortedRoomTypesAndOffers;
});
