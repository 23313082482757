export const PAYMENT_METHODS = {
  CASH: 'cash',
  POINTS: 'points',
};

export const PAYMENT_MODES = {
  FULL: 'FULL',
  DEPOSIT: 'DEPOSIT',
};

export const PAY_WITH_OPTIONS_PHONE = [
  {
    label: 'Cash',
    value: PAYMENT_METHODS.CASH,
  },
  {
    label: 'Points',
    value: PAYMENT_METHODS.POINTS,
  },
];

export const PAY_WITH_OPTIONS = [
  {
    label: 'Use cash',
    value: PAYMENT_METHODS.CASH,
  },
  {
    label: 'Use points',
    value: PAYMENT_METHODS.POINTS,
  },
];
