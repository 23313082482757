import wrappedAxios from './wrappedAxios';
import { captureErrorInSentry, isNonHttpError } from 'lib/errors';

export async function safeAxios(options) {
  try {
    await wrappedAxios(options);
  } catch (error) {
    if (isNonHttpError(error)) {
      captureErrorInSentry(error);
    }
  }
}
