import { LOCATION_CHANGE } from 'connected-next-router';
import { createLogic } from 'redux-logic';
import { parse as parseQueryString } from 'query-string';
import { COOKIE_NAMES, COOKIE_PATHS, COOKIE_MAX_AGES } from 'lib/enums/cookies';
import { setDateChangeMessaging } from 'store/ui/uiActions';

// search/map/property pages and luxe campaigns that link to checkout directly
const REFERRER_REGEX = /(\/hotels\/search\/|\/hotels\/properties|\/campaigns\/.*\/availability)/;
const isExternalReferringPage = () => !REFERRER_REGEX.test(window?.document?.referrer);

const haveDatesChanged = ({ latestCheckInDate, latestCheckOutDate, checkIn, checkOut }) =>
  !!latestCheckInDate &&
  !!latestCheckOutDate &&
  !!checkIn &&
  !!checkOut &&
  (latestCheckInDate !== checkIn || latestCheckOutDate !== checkOut);

// If we've entered the site on a page that has checkIn and checkOut dates for the
// first time from a referring page that is not one of the ones we can change dates
// on (which shouldn't happen anyway) and the dates are different to those last set
// then we want to warn the user.
// Every time the dates change we should set them for comparison against the next
// location change.
export const stayDateChangeLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,
  async process({ action, cookies }, dispatch, done) {
    const latestCheckInDate = cookies.get(COOKIE_NAMES.CHECK_IN_DATE);
    const latestCheckOutDate = cookies.get(COOKIE_NAMES.CHECK_OUT_DATE);

    const { checkIn, checkOut } = parseQueryString(action?.payload?.location?.search ?? '');
    const { isFirstRendering } = action.payload;

    if (isFirstRendering && haveDatesChanged({ latestCheckInDate, latestCheckOutDate, checkIn, checkOut }) && isExternalReferringPage()) {
      dispatch(setDateChangeMessaging({ checkIn, checkOut }));
    }

    cookies.set(COOKIE_NAMES.CHECK_IN_DATE, checkIn, {
      path: COOKIE_PATHS.HOTELS,
      maxAge: COOKIE_MAX_AGES.ONE_DAY_IN_SECONDS,
      sameSite: 'strict',
    });
    cookies.set(COOKIE_NAMES.CHECK_OUT_DATE, checkOut, {
      path: COOKIE_PATHS.HOTELS,
      maxAge: COOKIE_MAX_AGES.ONE_DAY_IN_SECONDS,
      sameSite: 'strict',
    });

    done();
  },
});
