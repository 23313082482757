import { LocalDate } from './localDate';

export const Today = () => {
  const d = new Date();
  const now = [d.getFullYear(), d.getMonth() + 1, d.getDate()];
  return new LocalDate(...now);
};

export const Tomorrow = () => {
  const d = new Date();
  const tomorrow = [d.getFullYear(), d.getMonth() + 1, d.getDate() + 1];
  return new LocalDate(...tomorrow);
};
