import {
  loginLogic,
  logoutLogic,
  setAuthenticationLogic,
  clearAuthenticationLogic,
  dismissSearchMessagingLogic,
  dismissLoginTooltipLogic,
  dismissWelcomeMessageLogic,
  locationChangeLogic,
} from './userLogic';

export const logic = [
  dismissSearchMessagingLogic,
  loginLogic,
  logoutLogic,
  setAuthenticationLogic,
  clearAuthenticationLogic,
  dismissLoginTooltipLogic,
  dismissWelcomeMessageLogic,
  locationChangeLogic,
];
