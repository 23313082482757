import { createAction } from '@reduxjs/toolkit';

export const updateFormData = createAction('checkout/UPDATE_FORM_DATA');
export const clearFormData = createAction('checkout/CLEAR_FORM_DATA');
export const updatePayments = createAction('checkout/UPDATE_PAYMENTS');
export const setBookingClientRequestId = createAction('checkout/SET_BOOKING_CLIENT_REQUEST_ID');
export const setBookingChannel = createAction('checkout/SET_BOOKING_CHANNEL', (payload) => ({ payload }));
export const addToCart = createAction<unknown>('checkout/ADD_TO_CART');
export const beginCheckout = createAction<unknown>('checkout/BEGIN_CHECKOUT');
export const checkoutError = createAction<unknown>('checkout/CHECKOUT_ERROR');
export const addPaymentInfo = createAction<unknown>('checkout/ADD_PAYMENT_INFO');
