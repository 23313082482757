import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import ServerRenderedGoogleTagManager from './targets/ServerRenderedGoogleTagManager';
import eventsMap from './eventsMap';

const createGtmMiddleware = ({ serverDataLayer, isServer }) => {
  const target = isServer ? ServerRenderedGoogleTagManager({ serverDataLayer }) : GoogleTagManager();
  return createMiddleware(eventsMap, target);
};

export default createGtmMiddleware;
