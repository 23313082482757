import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import queryValuesToString from 'lib/routing/queryValuesToString';

export const searchLocationAvailability = async ({ location, accessToken, qhUserId, page, ...query }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/locations/${location}/availability`,
    method: 'get',
    params: queryValuesToString({
      ...query,
      page: page || 1,
    }),
    accessToken,
    qhUserId,
  });

  return response.data;
};
