import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';

const MIN_DEALS_IN_CATEGORY = 2;
const PREFERRED_DEAL_TYPE_ORDER = {
  bonus_points: 1,
  luxury_offers: 2,
  flyer_deals: 3,
  best_deals: 4,
  classic_rewards: 5,
  chain_deals: 6,
  other_deals: 7,
};

const mapDealsToCategories = (deals = []) => {
  const { otherDeals, dealsByCategory, allPropertyIds } = reduce(
    deals,
    (accum, deal) => {
      const otherDeals = [...accum.otherDeals];
      const dealsByCategory = [...accum.dealsByCategory];
      const allPropertyIds = [...accum.allPropertyIds];
      const allowedProperties = reject(deal.results, (result) => allPropertyIds.includes(result.property.id));
      const allowedPropertyIds = allowedProperties.map(({ property }) => property.id);

      if (allowedProperties.length >= MIN_DEALS_IN_CATEGORY) {
        dealsByCategory.push({
          ...deal,
          results: allowedProperties,
        });
      } else {
        otherDeals.push(...allowedProperties);
      }

      return { otherDeals, dealsByCategory, allPropertyIds: [...allPropertyIds, ...allowedPropertyIds] };
    },
    { otherDeals: [], dealsByCategory: [], allPropertyIds: [] },
  );

  if (isEmpty(allPropertyIds)) return [];

  const result = [...dealsByCategory];

  if (otherDeals.length > 1) {
    result.push({
      type: {
        code: 'other_deals',
        name: 'Other deals',
      },
      results: otherDeals,
    });
  }

  const sortedResults = sortBy(result, ({ type }) => PREFERRED_DEAL_TYPE_ORDER[type.code]);

  return sortedResults;
};

export default mapDealsToCategories;
