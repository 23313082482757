import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchPointsBurnTiers = async () => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/points-burn-v2`,
    method: 'GET',
    timeout: 20000,
  });
  return response.data.pointsTierInstance;
};
