import React, { ReactNode, useEffect, useState } from 'react';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { getQhUserId } from 'store/user/userSelectors';
import { useRouter } from 'next/router';
import logger from 'server/lib/logger';
import { OPTIMIZELY_DATAFILE } from 'config';
import { useSelector } from 'react-redux';

let optimizelyInstance;

export const getOptimizely = () => {
  if (!optimizelyInstance) {
    optimizelyInstance = createInstance({
      eventBatchSize: 100,
      eventFlushInterval: 3000,
      odpOptions: {
        disabled: true,
      },
      sdkKey: OPTIMIZELY_DATAFILE,
    });
  }
  return optimizelyInstance;
};

export const OptimizelyProviderWrapper = ({ children }: { children: ReactNode }) => {
  const { isReady } = useRouter();
  const [userId, setUserId] = useState('default_user');
  const qhUserId = useSelector(getQhUserId);
  const optimizely = getOptimizely();

  useEffect(() => {
    if (isReady) {
      try {
        setUserId(qhUserId);
      } catch (error) {
        logger.error(error);
      }
    }
  }, [isReady, qhUserId]);

  if (optimizely && isReady) {
    return (
      <OptimizelyProvider optimizely={optimizely} user={{ id: userId, attributes: { user_id: userId } }}>
        {children}
      </OptimizelyProvider>
    );
  } else {
    logger.error('Optimizely error: cannot initialize optimizely');
    return <>{children}</>;
  }
};
