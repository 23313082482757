import { createSelector } from '@reduxjs/toolkit';
import startCase from 'lodash/startCase';
import { PROPERTY_TYPES_BY_TYPE } from 'lib/enums/search';
import { getQueryPropertyTypes } from 'store/router/routerSelectors';
import { getSearchMeta } from './searchSelectors';
import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

const PREFERRED_ORDER = {
  hotels: 1,
  apartments: 2,
  motels: 3,
  self_contained_apartments: 4,
  cabins_cottages: 5,
  bed_breakfasts: 6,
  resorts: 7,
  backpackers_hostels: 8,
  caravans_camping: 9,
};

const getNameFromType = (type) => (PROPERTY_TYPES_BY_TYPE[type] || { name: startCase(type) }).name;

const getSelectedPropertyTypeFilters = createSelector(getQueryPropertyTypes, (propertyTypes) =>
  propertyTypes.map((type) => ({ type, count: 0 })),
);

const getPropertyTypes = createSelector(getSearchMeta, (meta) => meta?.counts?.byPropertyType || []);

export const getAvailablePropertyTypes = createSelector(
  [getSelectedPropertyTypeFilters, getPropertyTypes],
  (selectedPropertyTypes, availablePropertyTypes) => {
    const combinedPropertyTypes = unionBy(availablePropertyTypes, selectedPropertyTypes, 'type');

    return sortBy(combinedPropertyTypes, ({ type }) => PREFERRED_ORDER[type])
      .filter((propertyType) => propertyType.type)
      .map((propertyType) => ({ ...propertyType, name: getNameFromType(propertyType.type) }));
  },
);
