import { createLogic } from 'redux-logic';
import { registerSplits, fetchTreatments } from 'store/split/splitActions';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import compact from 'lodash/compact';
import difference from 'lodash/difference';

const getNewSplits = ({ state, payload }) => {
  const currentSplits = Object.keys(get(state, 'split.treatments'));
  const splitsToRegister = isArray(payload) ? payload : compact([payload]);
  return difference(splitsToRegister, currentSplits);
};

export const registerSplitsLogic = createLogic({
  type: registerSplits,
  validate({ getState, action }, allow, reject) {
    const newSplits = getNewSplits({ state: getState(), payload: action.payload });
    newSplits.length ? allow(action) : reject();
  },
  async process(_, dispatch, done) {
    dispatch(fetchTreatments());

    done();
  },
});
