import loadUserDetails from './handlers/loadUserDetails';
import loadSplitVip from './handlers/loadSplitVip';
import loadClientType from './handlers/loadClientType';
import loadSplitTreatments from './handlers/loadSplitTreatments';
import loadBrowserDetails from './handlers/loadBrowserDetails';
import { createDispatchRegister } from 'lib/initialDispatch';
import loadRequestIp from './handlers/loadRequestIp';
import loadBotDetection from './handlers/loadBotDetection';
import loadCampaignMessages from './handlers/loadCampaignMessages';

export const loadReduxData = async ({ req, store, query }) => {
  loadRequestIp({ req, store }); // must happen before split.io treatments are requested
  loadSplitVip({ req, store }); // must happen before split.io treatments are requested
  loadBotDetection({ req, store }); // must happen before split.io treatments are requested
  loadUserDetails({ req, store });
  loadBrowserDetails({ req, store });
  loadClientType({ store, query });

  await loadSplitTreatments({ req, store });
  await loadCampaignMessages({ store });
};

const populateReduxStore = async ({ req, store, logicMiddleware, query }) => {
  const dispatchRegister = createDispatchRegister(logicMiddleware); // must be first so we can capture all dispatched actions

  await loadReduxData({ req, store, query });

  await loadCampaignMessages({ store });

  // Waits until all data fetching via redux logic has completed
  await logicMiddleware.whenComplete();
  return dispatchRegister;
};

export default populateReduxStore;
