import { theme as baseTheme } from '@qga/components';
import * as icons from '@qga/roo-ui/icons';

const theme = {
  ...baseTheme,
  icons: {
    ...icons,
    ...baseTheme.icons,
  },
};

export default theme;
