import { fetchSplitTreatments, trackSplitEvent } from 'lib/clients/split';

const createBrowserSplitClient = () => {
  return {
    getTreatments: async (splits, attributes, qhUserId) => {
      return await fetchSplitTreatments({ splits, attributes, qhUserId });
    },

    track: (event, value, properties) => {
      return trackSplitEvent({ event, value, properties });
    },
  };
};

export default createBrowserSplitClient;
