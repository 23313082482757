import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

interface Query {
  accessToken?: string;
  adults: number;
  dealType?: string;
  flightBookerToken?: string;
  location: string;
  limit: number;
  qhUserId: string;
}

export const fetchByDealType = async ({ accessToken, qhUserId, dealType, ...params }: Query) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/locations/deals/${dealType}`,
    method: 'GET',
    accessToken,
    qhUserId,
    params: { ...params, limit: 20 },
  });

  return response.data;
};

export const fetchByLocation = async ({ accessToken, qhUserId, ...params }: Query) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/locations/deals`,
    method: 'GET',
    accessToken,
    qhUserId,
    params: { ...params, limit: 4 },
  });

  return response.data;
};
