import { COOKIE_NAMES } from 'lib/enums/cookies';
import get from 'lodash/get';

const getUserDetails = (cookies) => {
  const flightBookerToken = get(cookies, COOKIE_NAMES.FLIGHT_BOOKER, null);
  const qhUserId = get(cookies, COOKIE_NAMES.QH_USER_ID, null);
  const hasDismissedSearchMessaging = !!get(cookies, COOKIE_NAMES.HIDE_SEARCH_MESSAGING);
  const hasDismissedLoginTooltip = !!get(cookies, COOKIE_NAMES.HIDE_LOGIN_TOOLTIP);
  const hasDismissedWelcomeMessage = !!get(cookies, COOKIE_NAMES.HIDE_WELCOME_MESSAGE);
  return { flightBookerToken, qhUserId, hasDismissedSearchMessaging, hasDismissedLoginTooltip, hasDismissedWelcomeMessage };
};

export default getUserDetails;
