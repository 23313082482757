import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useStore } from 'react-redux';
import authenticateUserOAuth from './authenticateUserOAuth';
import { useOAuthState } from './useOAuthState';

export const OAuthHandler = () => {
  const { route, asPath } = useRouter();
  const dispatch = useDispatch();
  const { getState } = useStore();
  const [state] = useOAuthState();

  useEffect(() => {
    if (!asPath.startsWith('/auth')) window.localStorage.setItem(state, JSON.stringify(asPath));
  }, [state, asPath]);

  // Might be running this too often with this
  useEffect(() => {
    if (route !== '/auth') authenticateUserOAuth(dispatch, getState);
  }, [route, dispatch, getState]);

  return null;
};
