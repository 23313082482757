import { createSelector } from '@reduxjs/toolkit';
import { getRouteFromPathName } from 'lib/routing/getRouteFromPathName';

export const getQueryString = (state) => state?.router?.location?.search || '';
export const getPathName = (state) => state?.router?.location?.pathname || '';
export const getRouteState = (state) => getPathName(state) + getQueryString(state);
export const getLocation = (state) => state?.router?.location || {};
export const getPageName = createSelector(getPathName, (pathName) => {
  return getRouteFromPathName(pathName);
});
