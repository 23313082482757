import compact from 'lodash/compact';
import intersection from 'lodash/intersection';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import sum from 'lodash/sum';

export const getStandardAvailabilityCounts = (roomTypes = []) => {
  const rooms = roomTypes.length || 0;
  const offers = sum(map(roomTypes, (roomType) => roomType.offers.length || 0));

  return {
    rooms,
    offers,
  };
};

export const getExclusiveAvailabilityCounts = (roomTypes = [], exclusiveOffer = {}) => {
  const roomIds = map(roomTypes, 'id');
  const offerIds = flatMap(roomTypes, (room) => map(room.offers, 'id'));

  const exclusiveRoomIds = compact(map(exclusiveOffer?.rooms, 'extranet.id', []));
  const exclusiveOfferIds = compact(flatMap(exclusiveOffer?.rooms, (room) => map(room.offers, 'id'), []));

  const availableRoomIds = intersection(roomIds, exclusiveRoomIds);
  const availableOfferIds = intersection(offerIds, exclusiveOfferIds);

  return {
    rooms: availableRoomIds.length,
    offers: availableOfferIds.length,
  };
};

export const getPropertyAvailabilityCounts = (roomTypes, exclusiveOffer) => {
  return exclusiveOffer ? getExclusiveAvailabilityCounts(roomTypes, exclusiveOffer) : getStandardAvailabilityCounts(roomTypes);
};
