import { createLogic } from 'redux-logic';
import { updateQuery } from 'store/search/searchActions';
import updateRouteQuery from 'lib/routing/updateRouteQuery';
import flow from 'lodash/flow';

/* When there is a new query, we need to reset to the first page of results */
const pageReset = (query) => ({ ...query, page: 1 });

/* The classic filter is only applicable in points mode, turn it off in cash mode */
const withCashMode = (query) => {
  if (query.payWith === 'cash') return { ...query, classicRewards: undefined };
  return query;
};

/* When the classic filter is switched on, we have to be in points mode*/
const withClassicFilter = (query) => {
  if (query.classicRewards) return { ...query, payWith: 'points' };
  return query;
};

const applyBusinessRules = flow(pageReset, withCashMode, withClassicFilter);

export const updateQueryLogic = createLogic({
  type: updateQuery,
  async process({ action, getState }, dispatch, done) {
    const newQuery = applyBusinessRules(action.payload);

    updateRouteQuery({ state: getState(), newQuery, dispatch });
    done();
  },
});
