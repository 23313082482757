import React, { FC, memo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps } from '@qga/roo-ui/components';

const QANTAS_UNIVERSAL_NAV_DESKTOP_HEIGHT = '40px';
const QANTAS_UNIVERSAL_NAV_BUNDLE_URL = 'https://frontend.qantas.com/widgets/universal-nav/current/app.js';

const StyleOverridesWrapper = styled(Box)`
  @media print {
    display: none;
  }
`;

const isScriptAlreadyLoaded = () => !!document.querySelectorAll(`[data-testid="qantas-universal-nav-script"]`).length;

const loadScript = () => {
  if (isScriptAlreadyLoaded()) {
    // ie11 does have Event constructor
    if (typeof Event === 'function') {
      document.dispatchEvent(new Event('universalNav.reload'));
    }
  } else {
    const script = document.createElement('script');
    script.setAttribute('data-testid', 'qantas-universal-nav-script');
    script.type = 'text/javascript';
    script.src = QANTAS_UNIVERSAL_NAV_BUNDLE_URL;
    document.body.appendChild(script);
  }
};

export interface SimplicityHeaderProps extends BoxProps {
  variant?: 'desktop' | 'mobile';
  hidden?: boolean;
  siteId?: 'hotel';
}

export const QantasUniversalNav: FC<SimplicityHeaderProps> = memo(
  ({
    variant = 'desktop',
    hidden = false,
    minHeight = QANTAS_UNIVERSAL_NAV_DESKTOP_HEIGHT,
    siteId = 'hotel',
    ...boxProps
  }: SimplicityHeaderProps) => {
    const simplicityProps = variant === 'mobile' ? { className: 'global_features_mobile' } : {};

    useEffect(() => {
      loadScript();
    }, []);

    return (
      <StyleOverridesWrapper hidden={hidden} data-testid="qantas-universal-nav-wrapper" minHeight={minHeight} {...boxProps}>
        <div {...simplicityProps} data-testid="qantas-universal-nav-target" data-widget-type="global_features_navigation">
          <script
            data-testid="qantas-universal-nav-config"
            type="qantas/widget"
            dangerouslySetInnerHTML={{
              __html: `{"environment": "production", "siteId": "${siteId}"}`,
            }}
          />
        </div>
      </StyleOverridesWrapper>
    );
  },
);
