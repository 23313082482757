import * as actions from 'store/termsAndConditions/termsAndConditionsActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchContent';
import { createAsyncLogic } from 'lib/logic';

export const fetchTermsAndConditionsLogic = createAsyncLogic({
  type: actions.fetchTermsAndConditions,
  latest: true,
  async process(_, dispatch) {
    const termsAndConditions = await client.fetchContent({ type: 'termsAndConditions' });
    dispatch(actions.setTermsAndConditions(termsAndConditions));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});
