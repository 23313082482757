import compact from 'lodash/compact';
import { ADYEN_MERCHANT_ID, ADYEN_API_VERSION, BRAND_CODE } from 'config';

const createVoucherPayment = (voucher, isViiVoucherOn) => {
  if (voucher?.amount?.greaterThan(0)) {
    return {
      type: isViiVoucherOn ? 'voucher' : 'hooroo_voucher',
      code: voucher.code,
      brand: BRAND_CODE,
      allocation: {
        amount: voucher.amount.toFixed(2),
        currency: 'AUD',
      },
      ...(isViiVoucherOn && { pin: voucher.pin }),
    };
  }
};

const createPointsPayment = (points) => {
  if (points?.amount?.greaterThan(0)) {
    return {
      type: 'qff_account',
      pointsAmount: points.amount.toFixed(0),
      pointsTier: points.tierVersionCode,
      allocation: {
        amount: points.amountInCash.toFixed(2),
        currency: 'AUD',
      },
    };
  }
};

const createPayNowAdyenCreditCardPayment = (cash) => {
  if (cash?.payableNow?.amount?.greaterThan(0)) {
    const {
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName,
      cardType,
      tokenizedCreditCard,
    } = cash.creditCard;

    if (!encryptedCardNumber || !encryptedExpiryMonth || !encryptedExpiryYear || !encryptedSecurityCode) {
      return;
    }

    return {
      type: 'adyen_encrypted_credit_card',
      apiVersion: ADYEN_API_VERSION,
      merchantId: ADYEN_MERCHANT_ID,
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName,
      tokenizedCreditCard,
      cardType,
      allocation: {
        amount: cash.payableNow.amount.toFixed(2),
        currency: 'AUD',
      },
    };
  }
};

const createPayLaterAdyenCreditCardPayment = (cash) => {
  if (cash?.payableLater?.amount?.greaterThan(0)) {
    const {
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName,
      cardType,
      tokenizedCreditCard,
    } = cash.creditCard;

    if (!encryptedCardNumber || !encryptedExpiryMonth || !encryptedExpiryYear || !encryptedSecurityCode) {
      return;
    }

    return {
      type: 'adyen_encrypted_credit_card',
      apiVersion: ADYEN_API_VERSION,
      merchantId: ADYEN_MERCHANT_ID,
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      encryptedSecurityCode,
      holderName,
      tokenizedCreditCard,
      cardType,
      deferredTo: cash.payableLater.dueDate,
      allocation: {
        amount: cash.payableLater.amount.toFixed(2),
        currency: 'AUD',
      },
    };
  }
};

const createTravelPassPayment = (travelPass) => {
  if (travelPass?.amount?.greaterThan(0)) {
    const { encryptedCardNumber, encryptedExpiryMonth, encryptedExpiryYear, amount } = travelPass;

    return {
      type: 'qantas_group_credit_voucher',
      apiVersion: ADYEN_API_VERSION,
      merchantId: ADYEN_MERCHANT_ID,
      encryptedCardNumber,
      encryptedExpiryMonth,
      encryptedExpiryYear,
      allocation: {
        amount: amount.toFixed(2),
        currency: 'AUD',
      },
    };
  }
};

const createPayments = ({ formData, isViiVoucherOn }) => {
  return compact([
    createPointsPayment(formData.payments.points),
    createPayNowAdyenCreditCardPayment(formData.payments.cash),
    createPayLaterAdyenCreditCardPayment(formData.payments.cash),
    createVoucherPayment(formData.payments.voucher, isViiVoucherOn),
    createTravelPassPayment(formData.payments.travelPass),
  ]);
};

const createBookingPayload = ({
  formData,
  quoteReference,
  ipAddress,
  deviceFingerprint,
  deviceFingerprintError,
  clientRequestId,
  bookingChannel,
  isViiVoucherOn,
}) => {
  const payments = createPayments({ formData, isViiVoucherOn });

  const { title, firstName, lastName, emailAddress, phoneNumber, abn, qffNumber, specialRequests } = formData;

  return {
    bookingChannel,
    clientRequestId,
    travelArranger: {
      name: {
        title,
        first: firstName,
        last: lastName,
      },
      email: emailAddress,
      phone: phoneNumber,
      businessNumber: abn,
    },
    stays: [
      {
        quoteReference,
        leadGuest: {
          name: {
            title,
            first: firstName,
            last: lastName,
          },
          qffNumber,
        },
        specialRequests,
      },
    ],
    payments,
    requestorDetails: {
      ipAddress: ipAddress,
      deviceFingerprint,
      deviceFingerprintError,
    },
  };
};

export default createBookingPayload;
