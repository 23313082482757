import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { emitUserInteraction } from 'store/userEnvironment/userEnvironmentActions';

export const useDataLayer = () => {
  const dispatch = useDispatch();

  const emitInteractionEvent = useCallback(
    ({ type, value, customAttributes }) => {
      dispatch(emitUserInteraction({ type, value, customAttributes }));
    },
    [dispatch],
  );

  return {
    emitInteractionEvent,
  };
};
