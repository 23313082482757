import { createSelector } from '@reduxjs/toolkit';
import { getSearchResults } from './searchSelectors';
import reduce from 'lodash/reduce';

export const getSearchGa4Results = createSelector(getSearchResults, (results) => {
  const searchResults = reduce(
    results,
    (accum, item) => {
      const mappedItem = {
        property: { ...item?.property, hasOffer: item?.offer?.promotion?.name ? true : false },
        roomType: item.roomType,
        offer: {
          charges: {
            total: item?.offer?.charges?.total,
            totalCash: item?.offer?.charges?.totalCash ? item?.offer?.charges?.totalCash : { amount: '0', currency: 'AUD' },
          },
        },
      };
      return [...accum, mappedItem];
    },
    [],
  );
  return searchResults;
});
