export const POINTS_CLUB = 'qff_points_club_standard';
export const POINTS_CLUB_PLUS = 'qff_points_club_plus';

export const POINTS_CLUB_TIERS_MAPPING = {
  'Points Club': POINTS_CLUB,
  'Points Club Plus': POINTS_CLUB_PLUS,
};

export const POINTS_CLUB_DISCOUNTS = {
  [`${POINTS_CLUB}`]: '25%',
  [`${POINTS_CLUB_PLUS}`]: '50%',
};
