import { EventType, createEvent } from '@qantasexperiences/analytics';
import { emitPropertyPageStandardGa4Event } from 'store/propertyAvailability/propertyAvailabilityActions';
import { emitPropertyPageExclusiveGa4Event } from 'store/exclusiveOffer/exclusiveOfferActions';

const emitViewItemEvent = ({ payload }) => {
  return createEvent({
    type: EventType.VIEW_ITEM,
    payload: {
      averagePrice: payload?.averagePrice,
      averagePricePoints: payload?.averagePricePoints,
      averagePriceBeforeDiscount: payload?.averagePriceBeforeDiscount,
      averagePointsEarned: payload?.averagePointsEarned,
      currency: 'AUD',
      recommendedProperty: payload?.recommendedProperty,
      luxuryOffer: payload?.luxuryOffer,
      checkIn: payload?.checkIn,
      checkOut: payload?.checkOut,
      adults: payload?.adults,
      children: payload?.children,
      infants: payload?.infants,
      availableOffers: payload?.availableOffers,
      availableRooms: payload?.availableRooms,
      rebook: payload?.rebook,
      payWith: payload?.payWith,
      location: payload?.location,
      results: payload?.results,
    },
  });
};

export default { [emitPropertyPageStandardGa4Event]: emitViewItemEvent, [emitPropertyPageExclusiveGa4Event]: emitViewItemEvent };
