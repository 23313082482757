import get from 'lodash/get';
import { minusOnePx, is, px } from './helpers';

const defaultBreakpoints = [425, 768, 1024].map((n) => n + 'px');

const createMediaQuery = (start, end) =>
  end
    ? `@media screen and (min-width: ${px(start)}) and (max-width: ${minusOnePx(px(end))})`
    : `@media screen and (min-width: ${px(start)})`;

const generateBreakpoints = (applyTo, breakpoints) => {
  return applyTo.reduce((acc, _, i, src) => {
    if (i === 0) return [createMediaQuery(0, breakpoints[0])];
    if (i === src.length - 1) return [...acc, createMediaQuery(breakpoints[i - 1])];
    return [...acc, createMediaQuery(breakpoints[i - 1], breakpoints[i])];
  }, []);
};

export const responsiveVariation = (propName, styleObject) => (props) => {
  const value = props[propName];
  const style = (n) => (n ? styleObject : null);

  // Early return for invalid prop
  if (!is(value)) return null;

  // Return single value if not given an array
  if (!Array.isArray(value)) return style(value);

  // calculate the breakpoints from the current theme based on the values supplied
  const breakpoints = generateBreakpoints(value, get(props.theme, 'breakpoints') || defaultBreakpoints);

  // create a style object for the breakpoints supplied
  return value.reduce((acc, curr, index) => {
    const media = breakpoints[index];
    const nestedStyle = { [media]: style(curr) };
    return curr ? { ...acc, ...nestedStyle } : acc;
  }, {});
};
