import { createReducer } from '@reduxjs/toolkit';
import { setUser, setAuthentication, clearAuthentication, setAuthenticationCompleted } from './userActions';

export const initialState = {
  qhUserId: null,
  flightBookerToken: null,
  hasDismissedSearchMessaging: null,
  hasDismissedLoginTooltip: null,
  hasDismissedWelcomeMessage: null,
  hasAuthenticationCompleted: false,
  authentication: {
    memberId: null,
    accessToken: null,
    title: null,
    firstName: null,
    lastName: null,
    emailAddress: null,
    phoneNumber: null,
    pointsBalance: null,
    membershipTier: null,
  },
};

export const reducer = createReducer(initialState, (builder) =>
  builder

    .addCase(setUser, (state, { payload = {} }) => {
      for (const [key, value] of Object.entries(payload)) {
        state[key] = value;
      }
    })

    .addCase(setAuthentication, (state, { payload }) => {
      state.authentication = payload;
    })

    .addCase(clearAuthentication, (state) => {
      state.authentication = initialState.authentication;
    })

    .addCase(setAuthenticationCompleted, (state) => {
      state.hasAuthenticationCompleted = true;
    }),
);
