import Bowser from 'bowser';
import { setBrowser } from 'store/userEnvironment/userEnvironmentActions';

const loadBrowserDetails = ({ req, store }) => {
  const userAgent = req?.headers['user-agent'];
  if (userAgent) {
    const client = Bowser.parse(userAgent);
    store.dispatch(setBrowser({ ...client.browser, type: client.platform.type, userAgent }));
  }
};

export default loadBrowserDetails;
