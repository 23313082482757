import { createLogic } from 'redux-logic';
import omit from 'lodash/omit';
import { routeToProperty } from 'store/search/searchActions';
import { getPropertyLinkQuery } from 'store/search/searchSelectors/getPropertyLinkQuery';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import setUrl from 'lib/routing/setUrl';
import { HOTELS_PATH } from 'config';

export const routeToPropertyLogic = createLogic({
  type: routeToProperty,
  async process({ action, getState }, dispatch, done) {
    const { id, excludeParams } = action.payload;
    const propertyLinkQuery = getPropertyLinkQuery(getState());
    const sanitizedQuery = omit(propertyLinkQuery, excludeParams);
    const propertyLinkQueryString = stringifyQueryValues(sanitizedQuery);
    setUrl(`${HOTELS_PATH}/properties/${id}?${propertyLinkQueryString}`);
    done();
  },
});
