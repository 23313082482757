import { createReducer } from '@reduxjs/toolkit';
import {
  fetchMemberFavourites,
  addMemberFavourite,
  removeMemberFavourite,
  setMemberFavourites,
  setMemberFavouriteError,
  clearMemberFavourites,
} from './memberFavouritesActions';

export const status = {
  PENDING: 'pending',
  RESOLVED: 'resolved',
  ERROR: 'error',
};

const initialState = {
  memberFavourites: null,
  status: status.RESOLVED,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchMemberFavourites, (state) => {
      state.status = status.PENDING;
    })
    .addCase(addMemberFavourite, (state) => {
      state.error = null;
      state.status = status.PENDING;
    })
    .addCase(removeMemberFavourite, (state) => {
      state.error = null;
      state.status = status.PENDING;
    })
    .addCase(setMemberFavourites, (state, { payload }) => {
      state.status = status.RESOLVED;
      state.memberFavourites = payload;
    })
    .addCase(setMemberFavouriteError, (state, { payload }) => {
      state.error = payload;
      state.status = status.ERROR;
    })
    .addCase(clearMemberFavourites, (state) => {
      state.memberFavourites = null;
      state.status = status.RESOLVED;
    }),
);
