import { HOTELS_API_URL } from 'config';
import wrappedAxios from './wrappedAxios';
import { COOKIE_NAMES } from 'lib/enums/cookies';

export const fetchSplitTreatments = async ({ splits, attributes, qhUserId }) => {
  // split id is referenced from cookie `qh_user_id`
  if (!qhUserId) {
    throw new Error('No qhUserId available for split treatment request');
  }

  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/split/treatments`,
    method: 'get',
    params: {
      splits: splits.join(','),
      attributes: JSON.stringify(attributes || {}),
    },
    headers: {
      [COOKIE_NAMES.QH_USER_ID]: qhUserId,
    },
  });

  return response.data;
};

export const trackSplitEvent = async ({ event, value, properties }) => {
  // split ids are referenced from cookies `qh_user_id` / `pbe_user_id`
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/split/events`,
    method: 'post',
    data: {
      event,
      value,
      properties,
    },
  });

  return response.data;
};
