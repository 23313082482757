import React, { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@qga/roo-ui/components';
import { getIsMobileApp } from 'store/ui/uiSelectors';

interface Props {
  children: ReactNode | ReactElement;
  href: string;
  onClick?: () => void;
  rel?: string;
  target?: string;
  title?: string;
  to?: string;
}

const ExternalLink = ({ children = null, title, target = '_blank', href, to, rel = 'noopener noreferrer', ...rest }: Props) => {
  const isMobileApp = useSelector(getIsMobileApp);

  const newTarget = isMobileApp ? '_blank' : target;
  const newRel = isMobileApp ? 'noopener noreferrer' : rel;
  const newTitle = isMobileApp ? 'Link opens in a new tab' : title;

  return (
    <Link href={href} to={to} rel={newRel} target={newTarget} title={newTitle} data-testid="external-link" {...rest}>
      {children}
    </Link>
  );
};

export default ExternalLink;
