import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import { useRouteData } from './useRouteData';
import { emitPageView } from 'store/userEnvironment/userEnvironmentActions';
import { getBookingChannel } from 'store/checkout/checkoutSelectors';
import { getHasAuthenticationCompleted } from 'store/user/userSelectors';
import { setPageViewEventFired } from 'store/pageViewEvent/pageViewEventActions';

export const useDataLayerPageVariables = () => {
  const [emitted, setEmitted] = useState(false);
  const trackedKeys = useRef([]);
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const bookingChannel = useSelector(getBookingChannel);
  const hasAuthenticationCompleted = useSelector(getHasAuthenticationCompleted);
  const { ready, ...routeData } = useRouteData();

  /* Reset "emitted" when path changes */
  useEffect(() => {
    setEmitted(false);
  }, [pathname]);

  /* Keep track of what variables have been set on previous pages */
  useEffect(() => {
    trackedKeys.current = uniq([...trackedKeys.current, ...keys(routeData)]);
  }, [routeData]);

  useEffect(() => {
    if (ready && !emitted && bookingChannel && hasAuthenticationCompleted) {
      const resetPayload = fromPairs(map(trackedKeys.current, (key) => [key, undefined]));
      const payload = { bookingChannel, ...resetPayload, ...routeData };
      dispatch(emitPageView(payload));
      setEmitted(true);
      dispatch(setPageViewEventFired());
    }
  }, [emitted, dispatch, ready, routeData, bookingChannel, hasAuthenticationCompleted]);
};
