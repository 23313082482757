import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { fetchSearchResultsSuccess } from 'store/search/searchActions';
import { getPageFromState } from './helpers';
import { getQueryParams } from 'store/router/routerSelectors';

const mapQueryParamToGTMVariables = ({ adults, children, infants, checkIn, checkOut, location }) => ({
  numAdults: adults,
  numChildren: children,
  numInfants: infants,
  checkIn,
  checkOut,
  location,
});

const emitFetchSearchResultsSuccessEvent = (action, prevState) => {
  const { results, meta } = action.payload;
  const page = getPageFromState(prevState);
  const queryParams = getQueryParams(prevState);

  const availablePropertyCount = meta?.counts?.filtered || 0;
  const totalPropertyCount = meta?.counts?.total || 0;

  const mappedGTMVariables = mapQueryParamToGTMVariables(queryParams);
  const customAttributes = {
    ...mappedGTMVariables,
    availablePropertyCount,
    totalPropertyCount,
  };

  const eventType = results.length > 0 ? 'Successful Search' : 'Unsuccessful Search';

  return interactionEvent({ type: eventType, value: queryParams.location, page: page.name, customAttributes });
};

export default { [fetchSearchResultsSuccess]: emitFetchSearchResultsSuccessEvent };
