import Decimal from 'decimal.js';
import filter from 'lodash/filter';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { createSelector } from '@reduxjs/toolkit';
import { BOOKING_STATES, BOOKING_ERROR_CODES } from 'lib/enums/booking';
import { without, flatten } from 'lodash';

export const getBookingState = (state) => get(state, 'booking.booking.state');
export const getBooking = (state) => get(state, 'booking.booking');
export const getReservation = (state) => get(state, 'booking.booking.reservation');
export const getLoadingError = (state) => get(state, 'booking.error');

export const getIsLoading = createSelector(getBooking, getLoadingError, (booking, loadingError) => !booking && !loadingError);

export const getIsCreating = createSelector(getBookingState, (bookingState) =>
  [BOOKING_STATES.SUBMITTING, BOOKING_STATES.ACCEPTING, BOOKING_STATES.BOOKING].includes(bookingState),
);

export const getRoomType = createSelector(getBooking, (booking) => get(booking, 'reservation.roomType'));

export const getOffer = createSelector(getBooking, (booking) => get(booking, 'reservation.offer'));

export const getIsNonRefundableCancellation = createSelector(getOffer, (offer) => get(offer, 'cancellationPolicy.isNonRefundable'));

export const getCancellationDescription = createSelector(getOffer, (offer) => get(offer, 'cancellationPolicy.description'));

export const getOfferInclusionsList = createSelector(getOffer, (offer) => {
  const inclusionsList = offer.inclusions.map((inclusion) => inclusion.name);
  return inclusionsList;
});

export const getOfferCharges = createSelector(getOffer, (offer) => get(offer, 'charges'));

export const getBookingId = createSelector(getBooking, (booking) => get(booking, 'id'));

export const getBookingReference = createSelector(getBooking, (booking) => get(booking, 'reference'));

export const getBookingTotal = createSelector(getBooking, (booking) => get(booking, 'bookingTotal'));

export const getVoucherAmount = createSelector(getBooking, (booking) => new Decimal(booking?.bookingTotal?.voucher?.total ?? 0));

export const getPayableNowCashAmount = createSelector(getBooking, (booking) => new Decimal(booking?.bookingTotal?.creditCard?.total ?? 0));

export const getPointsAmount = createSelector(getBooking, (booking) => new Decimal(booking?.bookingTotal?.points?.totalPoints ?? 0));

export const getTravelPassAmount = createSelector(
  getBooking,
  (booking) => new Decimal(booking?.bookingTotal?.qantasGroupCreditVoucher?.total ?? 0),
);

const getPayableLater = createSelector(getBooking, (booking) => (booking?.paymentSchedule ?? [])[0]);

export const getPayableLaterDueDate = createSelector(getPayableLater, (payableLater) => {
  const dateString = payableLater?.deferredTo;
  if (dateString) {
    return new Date(dateString);
  }
});

export const getPayableLaterCashAmount = createSelector(getPayableLater, (payableLater) => new Decimal(payableLater?.total?.amount || 0));

export const getProperty = createSelector(getBooking, (booking) => get(booking, 'reservation.property'));

export const getStayDates = createSelector(getBooking, (booking) => pick(booking.reservation, ['checkIn', 'checkOut']));

export const getOccupants = createSelector(getBooking, (booking) =>
  pick(get(booking, 'reservation', {}), ['adults', 'children', 'infants']),
);

export const getLeadGuest = createSelector(getReservation, (reservation) => {
  return {
    title: get(reservation, 'leadGuest.name.title'),
    firstName: get(reservation, 'leadGuest.name.given'),
    lastName: get(reservation, 'leadGuest.name.family'),
    qffNumber: get(reservation, 'leadGuest.qffNumber'),
  };
});

export const getTravelArranger = createSelector(getBooking, (booking) => {
  return {
    title: get(booking, 'travelArranger.name.title'),
    firstName: get(booking, 'travelArranger.name.given'),
    lastName: get(booking, 'travelArranger.name.family'),
    email: get(booking, 'travelArranger.email'),
    phone: get(booking, 'travelArranger.phone'),
    abn: get(booking, 'travelArranger.businessRegistrationNumber'),
  };
});

export const getQffPointsEarned = createSelector(getBooking, (booking) =>
  get(booking, 'reservation.offer.pointsEarned.qffPoints.total', 0),
);

export const getQbrPointsEarned = createSelector(getBooking, (booking) =>
  get(booking, 'reservation.offer.pointsEarned.qbrPoints.total', 0),
);

export const getBookingErrors = createSelector(getBooking, (booking) => get(booking, 'errors', []));

const CREDIT_CARD_ERROR_MATCHER = /^payment_failed_credit_card(.*)/;
const POINTS_ERROR_MATCHER = /^payment_failed_points(.*)/;
const VOUCHER_ERROR_MATCHER = /^payment_failed_voucher(.*)/;
const TRAVEL_PASS_ERROR_MATCHER = /^payment_failed_qantas_group_credit_voucher(.*)/;

// `payment_failed` is deliberately vague as it could suggest fraud
// `payment_price_mismatch` indicates that the payment amounts sent through
// don't add up to the total. This would indicate a bug in the checkout
// so we have a splunk alert to notify of this occurring.
export const getGenericPaymentErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => error.code === BOOKING_ERROR_CODES.PAYMENT_FAILED),
);

export const getCreditCardErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => CREDIT_CARD_ERROR_MATCHER.test(error.code)),
);

export const getPointsErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => POINTS_ERROR_MATCHER.test(error.code)),
);

export const getVoucherErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => VOUCHER_ERROR_MATCHER.test(error.code)),
);

export const getTravelPassErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => TRAVEL_PASS_ERROR_MATCHER.test(error.code)),
);

export const getPriceIncreaseErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => error.code === BOOKING_ERROR_CODES.PRICE_INCREASE),
);

export const getInventoryUnavailableErrors = createSelector(getBookingErrors, (errors) =>
  filter(errors, (error) => error.code === BOOKING_ERROR_CODES.INVENTORY_UNAVAILABLE),
);

// Fallback of all errors not specifically handled by one of the above selectors
// NOTE: If a new specific selector is added, it should be included here to exclude
// it from the non-specific error fallback.
export const getNonSpecificErrors = createSelector(
  getBookingErrors,
  getGenericPaymentErrors,
  getCreditCardErrors,
  getPointsErrors,
  getVoucherErrors,
  getTravelPassErrors,
  getPriceIncreaseErrors,
  getInventoryUnavailableErrors,
  (bookingErrors, ...handledErrors) => {
    return without(bookingErrors, ...flatten(handledErrors));
  },
);
