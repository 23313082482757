import { createReducer } from '@reduxjs/toolkit';
import { clearPageViewEventFired, setPageViewEventFired } from './pageViewEventActions';

export const initialState = {
  fired: false,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPageViewEventFired, (state) => {
      state.fired = true;
    })
    .addCase(clearPageViewEventFired, (state) => {
      state.fired = false;
    }),
);
