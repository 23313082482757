import { EMAIL_ENQUIRY_STATUS } from 'lib/enums/contactUs';
import * as client from 'lib/clients/createCallbackRequest';
import * as actions from 'store/enquiry/enquiryActions';
import { createAsyncLogic } from 'lib/logic';
import { addHours, isValid } from 'date-fns';
import { omitEmpty } from 'lib/object';

export const createCallbackRequestLogic = createAsyncLogic({
  type: actions.createCallbackRequest,
  latest: true,
  async process({ action }, dispatch) {
    dispatch(actions.setCallbackRequest({ status: EMAIL_ENQUIRY_STATUS.SUBMITTING }));

    const { callbackTime, callbackDate: callbackDateString, ...rest } = action.payload;
    const callbackDate = new Date(callbackDateString);
    const preferredStartTime = isValid(callbackDate) ? addHours(callbackDate, callbackTime).toISOString() : undefined;

    const payload = omitEmpty({
      ...rest,
      preferredStartTime,
    });

    const callbackRequest = await client.createCallbackRequest({ payload });

    if (callbackRequest.errors) {
      const error = new Error(callbackRequest?.message);
      error.response = callbackRequest;
      throw error;
    }

    dispatch(
      actions.setCallbackRequest({
        ...callbackRequest,
        status: EMAIL_ENQUIRY_STATUS.COMPLETE,
      }),
    );
  },
  onError({ parsedError }, dispatch) {
    dispatch(
      actions.setCallbackRequest({
        error: parsedError,
        status: EMAIL_ENQUIRY_STATUS.ERROR,
      }),
    );
  },
});
