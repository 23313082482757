import { format as formatDate } from 'lib/date';
import isNull from 'lodash/isNull';

const formatArray = (array) => array.map(format).join(',');

const format = (value) => {
  if (isNull(value)) {
    return undefined;
  } else if (value instanceof Date) {
    return formatDate(value);
  } else if (value instanceof Array) {
    return formatArray(value);
  } else if (value !== undefined) {
    return String(value);
  }
};

const queryValuesToString = (object) => {
  const transformedObject = {};

  Object.keys(object).forEach((key) => {
    transformedObject[key] = format(object[key]);
  });

  return transformedObject;
};

export default queryValuesToString;
