/*
 ** Copyright (C) 2018 Bloomberg LP. All rights reserved.
 ** This code is governed by the license found in the LICENSE file.
 */

/*
 * This class does not store or represent a time or time-zone.
 * Instead, it is a description of the date, as used for birthdays.
 * It cannot represent an instant on the time-line without additional information
 * such as an offset or time-zone.
 *
 * This class currently supports a subset of https://github.com/tc39/proposal-temporal#object-civildate
 * It does not not support conversion to civilDateTime
 */

import { number, pad, signedpad, plus, toDayOfWeek, toDayOfYear, toWeekOfYear, leapYearsBetween } from './utils';

const DATA = Symbol('DATA');

const EPOCH_YEAR = 1970;

export class LocalDate {
  constructor(years, months, days) {
    this[DATA] = plus({}, { years, months, days });
  }

  get year() {
    return this[DATA].year;
  }
  get month() {
    return this[DATA].month;
  }
  get day() {
    return this[DATA].day;
  }

  get dayOfWeek() {
    return toDayOfWeek(this.year, this.month, this.day);
  }
  get dayOfYear() {
    return toDayOfYear(this.year, this.month, this.day);
  }
  get weekOfYear() {
    return toWeekOfYear(this.year, this.month, this.day);
  }

  plus(data) {
    const { year, month, day } = plus(this, data);
    return new LocalDate(year, month, day);
  }
  with({ year = this.year, month = this.month, day = this.day } = {}) {
    return new LocalDate(year, month, day);
  }

  toString() {
    const { year, month, day } = this;
    return `${signedpad(year, 4)}-${pad(month, 2)}-${pad(day, 2)}`;
  }
  static fromString(string) {
    const match = /^(\d{4})-(\d{1,2})-(\d{1,2})$/.exec('' + string);
    if (!match) {
      throw new Error(`invalid date-time-string ${string}`);
    }
    return new LocalDate(number(match[1]), +match[2], +match[3]);
  }

  static fromDate(date) {
    return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  /**
   * The Epoch Day count is a simple incrementing count of days where day 0 is 1970-01-01 (ISO).
   * This definition is the same for all chronologies and time zones, enabling conversion.
   */
  toEpochDay() {
    const { year, month, day } = this;
    const daysInPrevYears = 365 * (year - EPOCH_YEAR);
    const daysThisYearWithoutToday = toDayOfYear(year, month, day) - 1;
    const leapDays = leapYearsBetween(EPOCH_YEAR, year);
    return daysInPrevYears + daysThisYearWithoutToday + leapDays;
  }
  static fromEpochDay(epochDay) {
    // NOTE: this could be optimised, far future dates will take longer.
    return new LocalDate(1970, 1, 1).plus({ days: number(epochDay) });
  }

  toJSON() {
    return this.toString();
  }
}

LocalDate.prototype[Symbol.toStringTag] = 'LocalDate';
