import { createLogic } from 'redux-logic';
import { clearFilters } from 'store/search/searchActions';
import updateRouteQuery from 'lib/routing/updateRouteQuery';
import { DEFAULT_FILTERS } from 'lib/enums/search';

export const clearFiltersLogic = createLogic({
  type: clearFilters,
  async process({ getState }, dispatch, done) {
    const newQuery = { ...DEFAULT_FILTERS, page: 1 };
    updateRouteQuery({ state: getState(), newQuery, dispatch });
    done();
  },
});
