import { getPageFromState } from './helpers';
import { scrollTrackingEvent } from 'lib/analytics/gtmEventShapes';
import { emitScrollTracking } from 'store/userEnvironment/userEnvironmentActions';

const emitScrollTrackingEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  const { pixels, distance } = action.payload;

  return scrollTrackingEvent({ pixels, distance, page: page.name });
};

export default { [emitScrollTracking]: emitScrollTrackingEvent };
