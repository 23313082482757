import * as actions from 'store/content/contentActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchContent';
import { createAsyncLogic } from 'lib/logic';

export const fetchContentLogic = createAsyncLogic({
  type: actions.fetchContent,
  latest: true,
  async process({ action }, dispatch) {
    const { payload } = action;
    const content = await client.fetchContent(payload);
    dispatch(actions.setContent(content));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
});
