import * as Sentry from '@sentry/nextjs';
import { IS_DEVELOPMENT } from 'config';

export const captureErrorInSentry = (error, extra = {}) => {
  Sentry.withScope((scope) => {
    Object.keys(extra).forEach((key) => {
      scope.setExtra(key, extra[key]);
    });
    const wrappedError = error instanceof Error || typeof error === 'string' ? error : new Error(JSON.stringify(error));

    if (IS_DEVELOPMENT) {
      console.log(wrappedError); // eslint-disable-line no-console
    } else {
      Sentry.captureException(wrappedError);
    }
  });
};
