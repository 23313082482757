import { getPageFromState } from './helpers';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { mapPropertyMarkerClick } from 'store/search/searchActions';

const emitMapPropertyMarkerClickedEvent = (_, prevState) => {
  const page = getPageFromState(prevState);

  return interactionEvent({ type: 'Price Marker', value: 'Price Selected', page: page.name });
};

export default { [mapPropertyMarkerClick]: emitMapPropertyMarkerClickedEvent };
