import { createSelector } from '@reduxjs/toolkit';
import { getAvailableRoomTypes, getHasValidQuery } from 'store/propertyAvailability/propertyAvailabilitySelectors';
import { getProperty } from 'store/property/propertySelectors';
import { getSearchQuery } from 'store/search/searchSelectors';

import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import every from 'lodash/every';
import { format } from 'lib/date';
import { getDomesticOrInternational, getStateCodeFromStateName } from 'lib/datalayer/helpers';

export const getPropertyVariables = createSelector([getHasValidQuery, getSearchQuery, getProperty], (hasQuery, query, property) => {
  if (!hasQuery || !property) return { ready: false };

  const country = property?.address?.country;
  const domesticOrInternational = getDomesticOrInternational(country);

  return {
    ready: true,
    domesticOrInternational,
    propertyName: property.name,
    propertyId: property.id,
    propertyCountryName: country,
    propertyStateCode: getStateCodeFromStateName(property?.address?.state),
    propertyCity: property?.address?.suburb,
    checkIn: query.checkIn && format(query.checkIn),
    checkOut: query.checkOut && format(query.checkOut),
    numAdults: query.adults,
    numChildren: query.children,
    numInfants: query.infants,
  };
});

export const getPropertyAvailabilityVariables = createSelector([getAvailableRoomTypes], (roomTypes) => {
  const allOffers = flatMap(roomTypes, (room) => room.offers);
  const promoNames = allOffers.map((offer) => offer?.promotion?.name);
  const promoCodes = allOffers.map((offer) => offer?.promotion?.promotionCode);
  const ready = !!roomTypes.length;

  return {
    ready,
    promotionNames: compact(uniq(promoNames)).join(', ') || 'none',
    promotionCodes: compact(uniq(promoCodes)).join(', ') || 'none',
  };
});

export const getPropertyPageDataLayerVariables = createSelector(
  [getPropertyVariables, getPropertyAvailabilityVariables],
  (propertyVariables, propertyAvailabilityVariables) => {
    const parts = [propertyVariables, propertyAvailabilityVariables];

    return {
      ...propertyVariables,
      ...propertyAvailabilityVariables,
      ready: every(parts, { ready: true }),
    };
  },
);
