import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchCampaign = async (props = {}) => {
  const response = await wrappedAxios({
    ...props,
    url: `${HOTELS_API_URL}/campaigns`,
    method: 'get',
  });

  //currently there is only ever a single campaign active so grab the first
  return response.data.campaigns[0];
};
