import difference from 'lodash/difference';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';

const filterCheckboxEvent = (prevFilter, nextFilter, pageKey, filterName) => {
  const removed = difference(prevFilter, nextFilter);
  const added = difference(nextFilter, prevFilter);

  const removedEvents = removed.map((removal) =>
    interactionEvent({ type: filterName, value: `${startCase(removal)} Unselected`, page: pageKey }),
  );
  const addedEvents = added.map((addition) =>
    interactionEvent({ type: filterName, value: `${startCase(addition)} Selected`, page: pageKey }),
  );

  return [...removedEvents, ...addedEvents];
};

export const propertyTypeEvent = (prevPropertyTypes, nextPropertyTypes, pageKey) =>
  filterCheckboxEvent(prevPropertyTypes, nextPropertyTypes, pageKey, 'Property Type Filter');

export const facilitiesEvent = (prevFacilities, nextFacilities, pageKey) =>
  filterCheckboxEvent(prevFacilities, nextFacilities, pageKey, 'Facilities Filter');

const createRatingEvent = (eventCategory, actions) => {
  return (value, page) => {
    const action = actions[value] || 'All Ratings Selected';
    return interactionEvent({ type: eventCategory, value: action, page: page.name });
  };
};

export const minStarRatingEvent = createRatingEvent('Star Rating Filter', {
  5: '5 Out Of 5 Selected',
  4: '4 And Up Selected',
  3: '3 And Up Selected',
  2: '2 And Up Selected',
  1: '1 And Up Selected',
});

export const minTripAdvisorRatingEvent = createRatingEvent('Tripadvisor Rating Filter', {
  5: 'Excellent 5 Selected',
  4: 'Very Good 4 And Up Selected',
  3: 'Averge 3 And Up Selected',
  2: '2 And Up Selected',
  1: '1 And Up Selected',
});

const getSubRegionNames = (availableSubRegions, subRegionIds) =>
  reduce(
    subRegionIds,
    (accum, id) => {
      const subRegion = find(availableSubRegions, ['id', id]);

      return [...accum, subRegion.name];
    },
    [],
  );

export const subRegionEvent = (availableSubRegions, prevSubRegionIds, nextSubRegionIds, pageKey) => {
  const prevSubRegions = getSubRegionNames(availableSubRegions, prevSubRegionIds);
  const nextSubRegions = getSubRegionNames(availableSubRegions, nextSubRegionIds);

  return filterCheckboxEvent(prevSubRegions, nextSubRegions, pageKey, 'Sub Regions Filter');
};
