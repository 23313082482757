import { createSelector } from '@reduxjs/toolkit';
import { createClassicRewardsAvailabilityMessage } from 'lib/search/createClassicRewardsAvailabilityMessage';
import { getQueryDepositPay } from 'store/router/routerSelectors';
import { getIsLoading } from './searchSelectors';

export const getClassicRewardAvailabilityMessage = createSelector(
  getIsLoading,
  getQueryDepositPay,
  createClassicRewardsAvailabilityMessage,
);
