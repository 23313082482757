import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import queryValuesToString from 'lib/routing/queryValuesToString';

export const searchRelatedPropertyAvailability = async ({ propertyId, checkIn, checkOut, adults, children, infants, payWith }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/properties/${propertyId}/related`,
    method: 'GET',
    params: queryValuesToString({
      checkIn,
      checkOut,
      adults,
      children,
      infants,
      payWith,
    }),
  });

  return response?.data;
};
