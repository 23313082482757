import { createSelector } from '@reduxjs/toolkit';
import { getQueryFacilities } from 'store/router/routerSelectors';
import { getSearchMeta } from './searchSelectors';
import keys from 'lodash/keys';
import compact from 'lodash/compact';

export const ORDERED_FACILITY_NAMES = {
  parking: 'Parking',
  breakfast: 'Breakfast',
  pool: 'Swimming pool',
  wifi: 'WiFi',
  pets: 'Pet Friendly',
  spa: 'Spa and wellness centre',
  restaurant: 'Bar, cafe and restaurant',
  bar: 'Bar',
  family: 'Family friendly',
  'room-service': 'Room service',
  shuttle: 'Airport shuttle',
  gym: 'Fitness centre',
  reception: 'Reception',
  beach: 'Beach Access',
  services: 'Services',
  accessible: 'Accessibility',
  transport: 'Transportation',
  business: 'Business/Conference centre',
};

const getFacilities = createSelector(getSearchMeta, (meta) => meta?.counts?.byFacility || []);

const createZeroCountFacility = (facilityType) => ({
  name: ORDERED_FACILITY_NAMES[facilityType],
  type: facilityType,
  count: 0,
});

export const getAvailableFacilities = createSelector(getFacilities, getQueryFacilities, (availableFacilities, selectedFacilities) =>
  compact(
    keys(ORDERED_FACILITY_NAMES).map((facilityType) => {
      let availableFacility = (availableFacilities || []).find(({ facility }) => facility === facilityType);

      if (availableFacility) {
        return { name: availableFacility.description, type: availableFacility.facility, count: availableFacility.count };
      } else if ((selectedFacilities || []).includes(facilityType)) {
        return createZeroCountFacility(facilityType);
      }
      return null;
    }),
  ),
);
