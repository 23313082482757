import * as actions from 'store/memberFavourites/memberFavouritesActions';
import * as client from 'lib/clients/addMemberFavourite';
import { getAccessToken, getMemberId, getQhUserId } from 'store/user/userSelectors';
import { getMemberFavourites } from 'store/memberFavourites/memberFavouritesSelectors';
import { createAsyncLogic } from 'lib/logic';

export const addMemberFavouriteLogic = createAsyncLogic({
  type: actions.addMemberFavourite,
  latest: true,
  async process({ getState, action }, dispatch) {
    const state = getState();

    const favourite = action.payload;
    const accessToken = getAccessToken(state);
    const qhUserId = getQhUserId(state);
    const memberId = getMemberId(state);

    if (!memberId) return;

    const { id: favouriteId, propertyId } = await client.addMemberFavourite({ accessToken, favourite, memberId, qhUserId });
    const currentMemberFavourites = getMemberFavourites(state);

    const favouriteByPropertyId = currentMemberFavourites?.[propertyId] ?? [];
    const updatedMemberFavourites = {
      ...currentMemberFavourites,
      [propertyId]: [...favouriteByPropertyId, favouriteId],
    };

    dispatch(actions.setMemberFavourites(updatedMemberFavourites));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.setMemberFavouriteError(parsedError));
    dispatch(actions.fetchMemberFavourites());
  },
});
