const ServerRenderedGoogleTagManager =
  ({ serverDataLayer }) =>
  (events) => {
    serverDataLayer = serverDataLayer || [];

    events.forEach((event) => {
      if (typeof event === 'object') {
        serverDataLayer.push(event);
      }
    });
  };

export default ServerRenderedGoogleTagManager;
