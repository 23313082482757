import { getLocationDealsQuery } from 'store/promoArea/promoAreaSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import * as client from 'lib/clients/searchLocationDeals';
import * as actions from 'store/promoArea/promoAreaActions';
import { createAsyncLogic } from 'lib/logic';

export const fetchDealsLogic = createAsyncLogic({
  type: actions.fetchDeals,
  cancelType: actions.cancelFetchDeals,
  latest: true,
  async process({ getState }, dispatch) {
    dispatch(actions.setLoading(true));

    const state = getState();
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const dealsQuery = getLocationDealsQuery(state);

    const { deals, meta } = await client.searchLocationDeals({
      ...dealsQuery,
      limit: 10,
      accessToken,
      flightBookerToken,
      qhUserId,
    });

    dispatch(actions.fetchDealsSuccess({ deals, meta }));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchDealsFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
