import { createReducer } from '@reduxjs/toolkit';
import { clearContent, fetchContent, setContent } from './contentActions';
import { STORE_STATUS } from 'lib/enums/store';

const initialState = {
  content: null,
  error: null,
  status: STORE_STATUS.PENDING,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchContent, (state) => {
      state.status = STORE_STATUS.PENDING;
    })
    .addCase(setContent, (state, { payload }) => {
      state.content = payload;
      state.error = null;
      state.status = STORE_STATUS.RESOLVED;
    })
    .addCase(clearContent, (state) => {
      state.content = null;
      state.error = null;
      state.status = STORE_STATUS.PENDING;
    }),
);
