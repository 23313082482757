import { createAction } from '@reduxjs/toolkit';

export const setLoading = createAction('promo_area/SET_LOADING');
export const setComponentState = createAction('promo_area/SET_COMPONENT_STATE');
export const setActiveCategory = createAction('promo_area/SET_ACTIVE_CATEGORY');
export const clearDeals = createAction('promo_area/CLEAR_DEALS');
export const fetchDeals = createAction('promo_area/FETCH_DEALS');
export const cancelFetchDeals = createAction('promo_area/CANCEL_FETCH_DEALS');
export const fetchDealsSuccess = createAction('promo_area/FETCH_DEALS_SUCCESS');
export const fetchDealsFailure = createAction('promo_area/FETCH_DEALS_FAILURE');
export const emitPromoAreaGa4Results = createAction('promo_area/EMIT_PROMO_AREA_GA4_RESULTS');
