import queryStringLib from 'query-string';
import mapValues from 'lodash/mapValues';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import { omitEmpty } from 'lib/object';
import { format as formatDate } from 'lib/date';
import { SEARCH_DATE_FORMAT } from 'config';

const formatValue = (value) => {
  if (isDate(value)) {
    return formatDate(value, SEARCH_DATE_FORMAT);
  } else if (isArray(value)) {
    return value.map((item) => formatValue(item)).join(',');
  } else if (isNumber(value)) {
    return String(value);
  } else {
    return value;
  }
};

const formatQuery = (query) => {
  return mapValues(query, (value) => {
    return formatValue(value);
  });
};

export default function stringifyQueryValues(query) {
  const formattedQuery = formatQuery(query);
  return queryStringLib.stringify(omitEmpty(formattedQuery));
}
