import wrappedAxios from './wrappedAxios';

import { HOTELS_API_URL } from 'config';

export const createBooking = async ({ payload, accessToken, qhUserId, splits }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/bookings`,
    method: 'post',
    data: payload,
    accessToken,
    qhUserId,
    splits,
    timeout: 60000, // give bookings longer than default of 30s to execute
  });

  return response.data;
};
