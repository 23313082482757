import reduce from 'lodash/reduce';
import * as client from 'lib/clients/fetchMemberFavourites';
import * as actions from 'store/memberFavourites/memberFavouritesActions';
import { getAccessToken, getMemberId, getQhUserId } from 'store/user/userSelectors';
import { createAsyncLogic } from 'lib/logic';

export const fetchMemberFavouritesLogic = createAsyncLogic({
  type: actions.fetchMemberFavourites,
  latest: true,
  async process({ getState }, dispatch) {
    const state = getState();

    const accessToken = getAccessToken(state);
    const qhUserId = getQhUserId(state);
    const memberId = getMemberId(state);

    if (!memberId) return;

    const favourites = await client.fetchMemberFavourites({ accessToken, memberId, qhUserId });
    const memberFavourites = reduce(
      favourites,
      (accum, favourite) => {
        const { id: favouriteId, propertyId } = favourite;
        const favourites = accum[propertyId] || [];

        return {
          ...accum,
          [propertyId]: [...favourites, favouriteId],
        };
      },
      {},
    );

    dispatch(actions.setMemberFavourites(memberFavourites));
  },
});
