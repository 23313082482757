import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from '@qga/roo-ui/components';
import NextLink from 'next/link';
import { getBrowser } from 'store/userEnvironment/userEnvironmentSelectors';
import { isLegacyBrowser } from 'lib/browser/isLegacyBrowser';

/* NOTE: this component cannot be upgraded to TS because it is imported in the config file as a prop for the
 * navigation menu. Because Cypress also imports the config file for global variables, it imports this typescript
 * file and then throws errors. Cypress will need a full overhaul to parse typescript within its own test files.
 */

// Links can be either a string or a { pathname, search } object
const parseRoutePath = (route) => {
  const { pathname, search } = route;
  if (!pathname) return route;
  return pathname + (search ? `?${search}` : '');
};

const AppLink = ({ children, to: route, target, href, ...rest }) => {
  const browser = useSelector(getBrowser);
  // NOTE: This is needed to prevent IE11 from opening in a new window.
  const forceLoadInSameTab = isLegacyBrowser(browser?.name);

  if (route) {
    return (
      <NextLink href={parseRoutePath(route)} passHref>
        <Link {...rest} target={forceLoadInSameTab ? '_self' : target}>
          {children}
        </Link>
      </NextLink>
    );
  } else {
    return (
      <Link {...rest} href={href} target={forceLoadInSameTab ? '_self' : target}>
        {children}
      </Link>
    );
  }
};

AppLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
};

AppLink.defaultProps = {
  children: null,
  to: null,
  href: null,
  target: '',
};

export default AppLink;
