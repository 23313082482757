import { isIOSDevice } from 'lib/browser';

export const fixBodyScroll = () => {
  const bodyEl = document.querySelector('body');

  if (!isIOSDevice()) {
    bodyEl.style.overflow = 'hidden';
  }

  if (isIOSDevice()) {
    const currentScroll = window.scrollY;
    bodyEl.style.position = 'fixed';
    bodyEl.style.left = 0;
    bodyEl.style.right = 0;
    bodyEl.style.top = `-${currentScroll}px`;
    bodyEl.style.bottom = 0;

    bodyEl.setAttribute('data-scroll-offset', currentScroll);
  }
};
