import { EMAIL_ENQUIRY_STATUS } from 'lib/enums/contactUs';
import * as client from 'lib/clients/createEnquiry';
import * as actions from 'store/enquiry/enquiryActions';
import createEnquiryPayload from './createEnquiryPayload';
import { createAsyncLogic } from 'lib/logic';

export const createEnquiryLogic = createAsyncLogic({
  type: actions.createEnquiry,
  latest: true,
  async process({ action }, dispatch) {
    dispatch(actions.setEnquiry({ status: EMAIL_ENQUIRY_STATUS.SUBMITTING }));

    const formData = action.payload;
    const payload = createEnquiryPayload(formData);
    const enquiry = await client.createEnquiry({ payload });

    if (enquiry.error) {
      dispatch(actions.setEnquiry({ error: enquiry.error, status: EMAIL_ENQUIRY_STATUS.ERROR }));
      return;
    }

    dispatch(
      actions.setEnquiry({
        enquiry,
        status: EMAIL_ENQUIRY_STATUS.COMPLETE,
      }),
    );
  },
  onError({ parsedError }, dispatch) {
    dispatch(
      actions.setEnquiry({
        error: parsedError,
        status: EMAIL_ENQUIRY_STATUS.ERROR,
      }),
    );
  },
});
