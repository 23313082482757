import { createReducer } from '@reduxjs/toolkit';
import { clearTermsAndConditions, fetchTermsAndConditions, setTermsAndConditions } from './termsAndConditionsActions';
import { STORE_STATUS } from 'lib/enums/store';

const initialState = {
  termsAndConditions: null,
  error: null,
  status: STORE_STATUS.PENDING,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchTermsAndConditions, (state) => {
      state.status = STORE_STATUS.PENDING;
    })

    .addCase(setTermsAndConditions, (state, { payload }) => {
      state.error = null;
      state.status = STORE_STATUS.RESOLVED;
      state.termsAndConditions = payload;
    })

    .addCase(clearTermsAndConditions, (state) => {
      state.termsAndConditions = null;
      state.error = null;
      state.status = STORE_STATUS.PENDING;
    }),
);
