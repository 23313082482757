import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchExclusiveOfferList = async () => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/`,
    method: 'GET',
  });

  return response.data;
};
