import { setUser } from 'store/user/userActions';
import { COOKIE_NAMES } from 'lib/enums/cookies';
import get from 'lodash/get';

const loadUserDetails = async ({ req, store }) => {
  const flightBookerToken = get(req, `cookies.${COOKIE_NAMES.FLIGHT_BOOKER}`, null);
  const qhUserId = get(req, `cookies.${COOKIE_NAMES.QH_USER_ID}`, null);
  const hasDismissedSearchMessaging = !!get(req, `cookies.${COOKIE_NAMES.HIDE_SEARCH_MESSAGING}`);
  const hasDismissedLoginTooltip = !!get(req, `cookies.${COOKIE_NAMES.HIDE_LOGIN_TOOLTIP}`);
  const hasDismissedWelcomeMessage = !!get(req, `cookies.${COOKIE_NAMES.HIDE_WELCOME_MESSAGE}`);
  store.dispatch(
    setUser({ flightBookerToken, qhUserId, hasDismissedSearchMessaging, hasDismissedLoginTooltip, hasDismissedWelcomeMessage }),
  );
};

export default loadUserDetails;
