import fromPairs from 'lodash/fromPairs';
import * as sessionStorage from '../browser/sessionStorage';

export const IMPRESSION_STORAGE_KEY = 'qantas_hotels_recommended_property_impressions';
export const INTERACTION_STORAGE_KEY = 'qantas_hotels_recommended_property_interactions';

export const registerInteraction = ({ propertyId, treatment, placement }) => {
  sessionStorage.merge(INTERACTION_STORAGE_KEY, {
    [propertyId]: { propertyId, treatment, placement, date: new Date().toISOString() },
  });
};

export const getInteraction = (propertyId) => {
  return sessionStorage.get(INTERACTION_STORAGE_KEY)?.[propertyId];
};

export const registerImpressions = ({ propertyIds, treatment, placement }) => {
  const date = new Date().toISOString();
  const impressions = fromPairs(propertyIds.map((propertyId) => [propertyId, { propertyId, treatment, placement, date }]));
  sessionStorage.merge(IMPRESSION_STORAGE_KEY, impressions);
};

export const getImpression = (propertyId) => {
  return sessionStorage.get(IMPRESSION_STORAGE_KEY)?.[propertyId];
};
