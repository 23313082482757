import { HOTELS_BRAND_NAME } from 'config';

export const CONTACT_US_PAGE_META = [
  { name: 'hotels-booking-stage', content: 'contact-us' },
  { name: 'robots', content: 'index, follow' },
  { name: 'description', content: `Contact us | ${HOTELS_BRAND_NAME} Australia` },
];

export const EMAIL_BREADCRUMBS = [
  { title: 'Contact us', url: '/contact-us' },
  { title: 'Email us', url: '/contact-us/email' },
];

export const EMAIL_ENQUIRY_STATUS = {
  ERROR: 'ERROR',
  READY: 'READY',
  SUBMITTING: 'SUBMITTING',
  COMPLETE: 'COMPLETE',
};

export const EMAIL_ENQUIRY_TYPES = {
  amendment: {
    label: 'I would like to amend my hotel reservation',
    value: 'Amendment',
  },
  cancellation: {
    label: 'I would like to cancel my hotel reservation',
    value: 'Cancellation',
  },
  existingReservation: {
    label: 'I have a question about my hotel reservation',
    value: 'Booking Enquiry',
  },
  qff: {
    label: 'I have a question about Frequent Flyer points',
    value: 'QFF Points',
  },
  general: {
    label: 'I have a general enquiry',
    value: 'General Enquiry',
  },
  voucher: {
    label: 'I have a question about using a Voucher',
    value: 'Voucher Enquiry',
  },
  airbnb: {
    label: 'I have a question about Airbnb/My Airbnb reservation',
    value: 'QFF Points',
  },
  supplier: {
    label: 'I am a supplier',
    value: 'Supplier',
  },
};
