import { createLogic } from 'redux-logic';
import { trackBooking } from 'store/booking/bookingActions';
import { createInteractionEventHandlers } from '../lib/createInteractionEventHandlers';

export const trackBookingLogic = createLogic({
  type: trackBooking,
  async process({ action, getState }, dispatch, done) {
    const { booking } = action.payload;

    const personalisation = createInteractionEventHandlers({ getState, dispatch });
    personalisation.trackBooking(booking.reservation.property.id);

    done();
  },
});
