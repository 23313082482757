import { IS_SERVER } from 'config';
import { captureErrorInSentry } from 'lib/errors';

const SUPPORTS_SESSION_STORAGE = typeof window !== 'undefined' && !IS_SERVER && !!window?.sessionStorage?.getItem;

const safe =
  (cb) =>
  (...args) => {
    try {
      if (SUPPORTS_SESSION_STORAGE) return cb(...args);
    } catch (error) {
      captureErrorInSentry(error);
    }
  };

const unsafeSet = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

const unsafeGet = (key) => {
  try {
    if (typeof window !== 'undefined') {
      return JSON.parse(window.sessionStorage.getItem(key)) || undefined;
    }
  } catch (error) {
    captureErrorInSentry(error);
    return undefined;
  }
};

const unsafeMerge = (key, updates) => {
  unsafeSet(key, { ...unsafeGet(key), ...updates });
};

export const get = safe(unsafeGet);

export const set = safe(unsafeSet);

export const merge = safe(unsafeMerge);
