import omit from 'lodash/omit';
import * as actions from 'store/memberFavourites/memberFavouritesActions';
import * as client from 'lib/clients/removeMemberFavourite';
import { getAccessToken, getMemberId, getQhUserId } from 'store/user/userSelectors';
import { getMemberFavourites } from 'store/memberFavourites/memberFavouritesSelectors';
import { createAsyncLogic } from 'lib/logic';

export const removeMemberFavouriteLogic = createAsyncLogic({
  type: actions.removeMemberFavourite,
  latest: true,
  async process({ getState, action }, dispatch) {
    const state = getState();
    const { payload: propertyId } = action;

    const accessToken = getAccessToken(state);
    const qhUserId = getQhUserId(state);
    const memberId = getMemberId(state);

    if (!memberId) return;

    const currentMemberFavourites = getMemberFavourites(state);

    const favouritesToDelete = currentMemberFavourites[propertyId];

    await Promise.all(
      favouritesToDelete.map((favouriteId) => client.removeMemberFavourite({ accessToken, favouriteId, memberId, qhUserId })),
    );

    const updatedMemberFavourites = omit(currentMemberFavourites, [propertyId]);
    dispatch(actions.setMemberFavourites(updatedMemberFavourites));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.setMemberFavouriteError(parsedError));
    dispatch(actions.fetchMemberFavourites());
  },
});
