import React from 'react';
import ErrorUI from 'components/errors/ErrorUI';
import { Button, Text, Box } from '@qga/roo-ui/components';

const reloadPage = () => {
  window.location.reload();
};

const PageError = () => (
  <ErrorUI heading="Sorry, something went wrong">
    <Box>
      <Text fontSize="base">
        We&#39;re aware that something isn&#39;t quite right and we&#39;re in the middle of fixing it.
        <br />
        Try refreshing the page or come back again later.
      </Text>
    </Box>
    <Button my={8} variant="primary" onClick={reloadPage}>
      Try again
    </Button>
  </ErrorUI>
);

export default PageError;
