import { createLogic } from 'redux-logic';
import { resolveDeviceFingerprint, setDeviceFingerprint, setDeviceFingerprintFailure } from 'store/userEnvironment/userEnvironmentActions';
import { getDeviceFingerprint } from 'lib/fraud';

export const resolveDeviceFingerprintLogic = createLogic({
  type: resolveDeviceFingerprint,
  latest: true,
  async process(_, dispatch, done) {
    try {
      const deviceFingerprint = await getDeviceFingerprint();
      dispatch(setDeviceFingerprint(deviceFingerprint));
    } catch (error) {
      dispatch(setDeviceFingerprintFailure(error.toString()));
      // can't set the device fingerprint, nothing to be done
    } finally {
      done();
    }
  },
});
