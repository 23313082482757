import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';
import queryValuesToString from 'lib/routing/queryValuesToString';

export const searchPropertyAvailability = async ({
  propertyId,
  checkIn,
  checkOut,
  adults,
  children,
  infants,
  accessToken,
  flightBookerToken,
  payWith,
  qhUserId,
}) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/properties/${propertyId}/availability`,
    method: 'get',
    params: queryValuesToString({
      checkIn,
      checkOut,
      adults,
      children,
      infants,
      flightBookerToken,
      payWith,
    }),
    accessToken,
    qhUserId,
  });

  return response.data;
};
