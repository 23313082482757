import get from 'lodash/get';
import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchBookingById = async ({ bookingId, qhUserId, cookie }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/bookings/${bookingId}?cachebuster=${Math.random()}`,
    method: 'GET',
    qhUserId,
    cookie,
  });

  return get(response, 'data.booking', {});
};
