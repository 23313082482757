import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { openPointsClubModal } from 'store/ui/uiActions';
import { getPageFromState } from './helpers';
import { getIsAuthenticated, getIsAuthenticatedOAuth } from 'store/user/userSelectors';
import { TRACKING_PREFIX } from 'config';

const searchPage = `${TRACKING_PREFIX} Search Page`;
const propertyPage = `${TRACKING_PREFIX} Property Page`;
const checkoutPage = `${TRACKING_PREFIX} Booking Page`;
const bookingConfirmationPage = `${TRACKING_PREFIX} Booking Confirmation Page`;

const eventTypes = {
  [searchPage]: {
    qff: 'Points Club Welcome Banner',
    guest: 'Points Club Login Banner',
  },
  [propertyPage]: {
    qff: 'Points Club Welcome Banner',
    guest: 'Points Club Login Banner',
  },
  [checkoutPage]: {
    qff: 'Points Club Note',
    guest: '',
  },
  [bookingConfirmationPage]: {
    qff: 'Points Club Earned Banner',
    guest: '',
  },
};

const emitOpenPointsClubModalEvent = (_, prevState) => {
  const pageName = getPageFromState(prevState).name;

  const isAuthenticated = getIsAuthenticated(prevState) || getIsAuthenticatedOAuth(prevState);

  const accountType = isAuthenticated ? 'qff' : 'guest';
  const eventType = eventTypes?.[pageName]?.[accountType];

  if (eventType) {
    return interactionEvent({ type: eventType, value: 'Points Club Modal Opened', page: pageName });
  }
};

export default { [openPointsClubModal]: emitOpenPointsClubModalEvent };
